import {Button, Label, toast} from '@cashiaApp/web-components';
import {useState, useMemo, useEffect, useRef, useCallback} from 'react';
import {useParams} from 'react-router-dom';

import {FormSection} from './components/FormSection';
import {FormValues, ValidationErrors} from './types/types';
import {ReactComponent as AvailableBalanceIcon} from '../../assets/icons/available_balance_icon.svg';
import {ReactComponent as DropdownIcon} from '../../assets/icons/downArrow.svg';
import {ReactComponent as FacebookIcon} from '../../assets/icons/facebook.svg';
import {ReactComponent as ImagePlaceHolderIcon} from '../../assets/icons/img-placeholder.svg';
import {ReactComponent as InstagramIcon} from '../../assets/icons/instagram.svg';
import {ReactComponent as Delete} from '../../assets/icons/red-trash.svg';
import {ReactComponent as TiktokIcon} from '../../assets/icons/tiktok.svg';
import {ReactComponent as TotalBalanceIcon} from '../../assets/icons/total_balance_icon.svg';
import CustomAutocomplete from '../../components/common/AddressInput';
import CustomInput from '../../components/common/CustomInput';
import CustomPhoneInput from '../../components/common/CustomPhoneInput';
import CustomSpinner from '../../components/common/CustomSpinner';
import DropDown from '../../components/common/DropDown';
import FormInput from '../../components/common/FormInput';
import Navbar from '../../components/common/Navbar';
import StatusDot from '../../components/common/StatusDot';
import {
  ComplianceStatus,
  CurrencyCode,
  Media,
  useGetBanksQuery,
  useGetBusinessCategoriesQuery,
  useGetBusinessQuery,
  useUpdateBusinessDetailsMutation,
  useUploadMediaMutation,
  VerificationIdType,
} from '../../generated';
import userInitials from '../../utils/getNameInitials';
import {hasDigits} from '../../utils/helper/validation';
import {getFileNameFromUrl} from '../../utils/helpers';
import {cn} from '../../utils/reusablefunctions';
import RenderErrorMessage from '../../utils/reusablefunctions/errorMessage';
import formatDate from '../../utils/reusablefunctions/formatDate';
import validateKenyanPhoneNumber from '../../utils/validatePhoneNumber';

const validateBusinessInfo = (data: FormValues) => {
  const errors: Record<string, string> = {};

  if (!data.businessName?.trim()) {
    errors.businessName = 'Business name is required';
  }
  if (!data.businessCategory?.trim()) {
    errors.category = 'Category is required';
  }
  if (!data.businessDescription?.trim()) {
    errors.description = 'Description is required';
  }
  if (!data.phoneNumber?.number?.trim()) {
    errors.phoneNumber = 'Phone number is required';
  } else if (!validateKenyanPhoneNumber(data.phoneNumber.number.trim())) {
    errors.phoneNumber = 'Phone number is invalid';
  }
  if (!data.businessAddress?.trim()) {
    errors.location = 'Location is required';
  }

  return errors;
};

const validateOwnerInfo = (data: FormValues) => {
  const errors: Record<string, string> = {};

  if (!data.ownerFirstName?.trim()) errors.firstName = 'First name is required';
  if (!data.ownerLastName?.trim()) errors.lastName = 'Last name is required';
  if (!data.idType) errors.idType = 'ID type is required';
  if (!data.idPhotos?.length) errors.id = 'Identity document is required';
  if (!data.ownerAddress?.trim()) errors.address = 'Address is required';

  return errors;
};

const validateBankInfo = (data: FormValues) => {
  const errors: Record<string, string> = {};

  if (!data.bankName) errors.bankId = 'Bank name is required';
  if (!data.accountHolder?.trim()) {
    errors.accountHolder = 'Account holder name is required';
  }
  if (!data.accountNumber?.trim()) {
    errors.accountNumber = 'Account number is required';
  } else {
    const num = data.accountNumber.trim();
    if (!/^\d*$/.test(num)) {
      errors.accountNumber =
        'Please enter a valid Account Number using only numeric characters.';
    } else if (num.length < 10) {
      errors.accountNumber = 'Account number must be at least 10 digits.';
    } else if (num.length > 14) {
      errors.accountNumber = 'Account number must not exceed 14 digits.';
    }
  }

  return errors;
};

const validateSocialMedia = (data: FormValues) => {
  const errors: Record<string, string> = {};

  const isValidUrl = (url: string) => {
    try {
      const urlObj = new URL(url);
      return (
        ['http:', 'https:'].includes(urlObj.protocol) &&
        urlObj.hostname.includes('.') &&
        /\.[a-z]{2,}$/i.test(urlObj.hostname)
      );
    } catch {
      return false;
    }
  };

  if (data.website && !isValidUrl(data.website)) {
    errors.website =
      'Please enter a valid website URL (e.g., https://www.example.com)';
  }

  if (data.facebook) {
    const facebookUrlRegex =
      /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.]{5,}\/?$/;
    if (!facebookUrlRegex.test(data.facebook)) {
      errors.facebook =
        'Please enter a valid Facebook URL (e.g., https://www.facebook.com/username)';
    }
  }

  if (data.instagram) {
    const instagramUrlRegex =
      /^(https?:\/\/)?(www\.)?instagram\.com\/([a-zA-Z0-9._]+)\/?$/;
    if (!instagramUrlRegex.test(data.instagram)) {
      errors.instagram =
        'Please enter a valid Instagram URL (e.g., https://www.instagram.com/username)';
    }
  }

  if (data.tiktok) {
    const tiktokUrlRegex =
      /^(https?:\/\/)?(www\.)?tiktok\.com\/@[a-zA-Z0-9._-]+\/?$/;
    if (!tiktokUrlRegex.test(data.tiktok)) {
      errors.tiktok =
        'Please enter a valid TikTok URL (e.g., https://www.tiktok.com/@username)';
    }
  }

  return errors;
};

const FormFieldWrapper: React.FC<{
  label: string;
  required?: boolean;
  children: React.ReactNode;
}> = ({label, required, children}) => {
  return (
    <div className="mb-6 max-md:mb-0">
      <p className="font-medium text-grey pb-1 sm:text-[15px] md:text-[16px] flex items-center">
        {label} {required && <span>*</span>}
      </p>
      {children}
    </div>
  );
};

const breadcrumbs = [
  {
    name: 'Business Profiles',
    location: '/business-profiles',
  },
  {
    name: 'Business Profile Details',
  },
];

const complianceStatus = {
  [ComplianceStatus.AwaitingSubmission]: 'Awaiting submission',
  [ComplianceStatus.Compliant]: 'Compliant',
  [ComplianceStatus.Pending]: 'Pending',
  [ComplianceStatus.NonCompliant]: 'Non Compliant',
};

const idTypes = {
  [VerificationIdType.AlienCard]: 'Alien',
  [VerificationIdType.NationalId]: 'National ID',
  [VerificationIdType.Passport]: 'Passport',
};

interface SectionState {
  isEditing: boolean;
  isSubmitting: boolean;
}

const prepareAddressInput = (
  address: string,
  coordinates: {lat: number; long: number},
  buildingName?: string
) => ({
  location: address,
  coordinates: coordinates,
  buildingName: buildingName || '',
});

const isFile = (file: File | Media): file is File => {
  return 'name' in file && typeof file.name === 'string';
};
interface AddressFieldProps {
  type: 'business' | 'residential';
  isEditing: boolean;
  currentValue: string;
  onAddressChange: (address: string) => void;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  setAddressValidationState: React.Dispatch<
    React.SetStateAction<{
      businessAddress: boolean;
      residentialAddress: boolean;
    }>
  >;
}

const labels = {
  business: 'Business Address',
  residential: 'Residential Address',
};

const RenderAddressField: React.FC<AddressFieldProps> = ({
  type,
  isEditing,
  currentValue,
  onAddressChange,
  errors,
  setErrors,
  setAddressValidationState,
}) => {
  const errorMessages = useMemo(
    () => ({
      business: {
        validation: errors.businessInfo?.invalidBusinessAddress,
        required: errors.businessInfo?.location,
      },
      residential: {
        validation: errors.ownerInfo?.invalidResidentialAddress,
        required: errors.ownerInfo?.address,
      },
    }),
    [
      errors.businessInfo?.invalidBusinessAddress,
      errors.businessInfo?.location,
      errors.ownerInfo?.invalidResidentialAddress,
      errors.ownerInfo?.address,
    ]
  );

  const getErrorMessage = () => {
    const messages = errorMessages[type];
    return messages.required || messages.validation;
  };

  const handleAddressSelect = (value: string) => {
    onAddressChange(value);
    if (type === 'business') {
      setErrors((prev) => ({
        ...prev,
        businessInfo: {
          ...prev.businessInfo,
          invalidBusinessAddress: undefined,
          location: undefined,
        },
      }));
      setAddressValidationState((prev) => ({
        ...prev,
        businessAddress: true,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        ownerInfo: {
          ...prev.ownerInfo,
          invalidResidentialAddress: undefined,
          address: undefined,
        },
      }));
      setAddressValidationState((prev) => ({
        ...prev,
        residentialAddress: true,
      }));
    }
  };

  const handleAddressBlur = async (isValid: boolean) => {
    if (!isValid) {
      if (type === 'business') {
        setErrors((prev) => ({
          ...prev,
          businessInfo: {
            ...prev.businessInfo,
            invalidBusinessAddress:
              'Please select an address from the suggestions list',
            location: undefined,
          },
        }));
        setAddressValidationState((prev) => ({
          ...prev,
          businessAddress: false,
        }));
        onAddressChange('');
      } else {
        setErrors((prev) => ({
          ...prev,
          ownerInfo: {
            ...prev.ownerInfo,
            invalidResidentialAddress:
              'Please select an address from the suggestions list',
            address: undefined,
          },
        }));
        setAddressValidationState((prev) => ({
          ...prev,
          residentialAddress: false,
        }));
        onAddressChange('');
      }
    }
  };

  if (isEditing) {
    return (
      <div className="w-[480px] flex flex-col gap-2">
        <Label className="font-medium text-grey sm:text-[15px] md:text-[16px]">
          {labels[type]} *
        </Label>
        <div className="h-[70px]">
          <div>
            <CustomAutocomplete
              onSelect={handleAddressSelect}
              onBlur={handleAddressBlur}
              defaultValue={currentValue}
              error={getErrorMessage()}
            />
          </div>
          <div className="text-red-500 text-sm mt-1">
            {getErrorMessage() && (
              <RenderErrorMessage error={getErrorMessage()} />
            )}
          </div>
        </div>
      </div>
    );
  }

  return <CustomInput label={labels[type]} value={currentValue} />;
};

const readableCurrencyCodeInfo = (type: CurrencyCode) => {
  return {
    KES: {
      name: 'KSH',
      fullName: 'Kenyan Shilling',
    },
    USD: {
      name: 'USD',
      fullName: 'US Dollar',
    },
  }[type];
};

const ProfileDetails = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(CurrencyCode.Kes);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dropdownCategoryOpen, setDropdownCategoryOpen] = useState(false);
  const [dropdownIdTypeOpen, setDropdownIdTypeOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [addressValidationState, setAddressValidationState] = useState({
    businessAddress: true,
    residentialAddress: true,
  });
  const {data: banksData} = useGetBanksQuery();
  const banks = useMemo(() => {
    const sortedBanks = [...(banksData?.banks || [])];
    return sortedBanks.sort((a, b) => a.name.localeCompare(b.name));
  }, [banksData]);
  const [errors, setErrors] = useState<ValidationErrors>({
    businessInfo: {},
    ownerInfo: {},
    bankInfo: {},
    socialMedia: {},
  });

  const {id} = useParams<{id: string}>();
  const {data: categoriesData} = useGetBusinessCategoriesQuery();

  const categories = useMemo(() => {
    const copy = [...(categoriesData?.businessCategories || [])];
    return copy.sort((a, b) => a?.name?.localeCompare(b?.name));
  }, [categoriesData]);

  const {
    data: businessData,
    loading,
    refetch: refetchBusiness,
  } = useGetBusinessQuery({
    variables: {
      id: id,
    },
    skip: !id,
  });

  const [updateBusiness] = useUpdateBusinessDetailsMutation({
    onCompleted: () => {
      refetchBusiness();
    },
    onError: (error) => {
      toast.error(`Error updating business profile: ${error.message}`);
    },
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadMedia, {loading: uploadMediaLoading, error: uploadMediaError}] =
    useUploadMediaMutation();

  const business = useMemo(() => businessData?.business, [businessData]);

  const initials = useMemo(
    () => (business ? userInitials(business?.name || '') : ''),
    [business]
  );

  const userName = useMemo(
    () =>
      `${businessData?.business?.members?.[0]?.firstName || ''} ${businessData?.business?.members?.[0]?.lastName || ''}`,
    [businessData]
  );
  const [values, setValues] = useState<FormValues>();

  const [ownerInfoState, setOwnerInfoState] = useState<SectionState>({
    isEditing: false,
    isSubmitting: false,
  });
  const [bankDetailsState, setBankDetailsState] = useState<SectionState>({
    isEditing: false,
    isSubmitting: false,
  });
  const [socialMediaState, setSocialMediaState] = useState<SectionState>({
    isEditing: false,
    isSubmitting: false,
  });

  const handleBusinessInfoUpdate = async () => {
    if (!values) return;
    setIsSubmitting(true);
    const businessInfoErrors = validateBusinessInfo(values);

    setErrors((prev) => ({
      ...prev,
      businessInfo: businessInfoErrors,
    }));

    if (
      Object.keys(businessInfoErrors).length === 0 &&
      !errors.businessInfo?.invalidBusinessAddress &&
      addressValidationState.businessAddress
    ) {
      try {
        await updateBusiness({
          variables: {
            id: id || '',
            input: {
              name: values?.businessName,
              categoryId: values?.businessCategory,
              description: values?.businessDescription,
              phoneNumber: values?.phoneNumber,
              address: prepareAddressInput(
                values?.businessAddress ?? '',
                business?.address?.location.coordinates || {lat: 0, long: 0},
                values?.buildingName
              ),
            },
          },
        });
        setIsEditing(false);
        setIsSubmitting(false);
        toast.success('Business information updated successfully');
      } catch (error) {
        setIsSubmitting(false);
        setIsEditing(false);
        console.error('Error updating business info:', error);
      }
    }
    setIsSubmitting(false);
  };
  const resetToOriginalValues = useCallback(() => {
    setValues({
      businessName: business?.name || '',
      businessCategory: business?.category?.id || '',
      phoneNumber: {
        countryCode: business?.phoneNumber?.countryCode || '',
        number: business?.phoneNumber?.number || '',
      },
      businessAddress: business?.address?.location.formattedAddress || '',
      businessDescription: business?.description || '',
      buildingName: business?.address?.buildingName || '',
      ownerFirstName: business?.owner?.firstName || '',
      ownerLastName: business?.owner?.lastName || '',
      idType: business?.owner?.idType || VerificationIdType.NationalId,
      idFile: business?.owner?.idPhotos?.[0] || undefined,
      idFileName: business?.owner?.idPhotos?.[0]?.url?.split('/')?.pop() || '',
      idPhotos: business?.owner?.idPhotos?.map((photo) => photo.id) || [],
      ownerAddress: business?.owner?.address.location.formattedAddress || '',
      ownerBuildingName: business?.owner?.address.buildingName || '',
      bankName: business?.bankAccount?.bank.id || '',
      accountHolder: business?.bankAccount?.accountHolder || '',
      accountNumber: business?.bankAccount?.accountNumber || '',
      website: business?.socialMedia?.website || '',
      facebook: business?.socialMedia?.facebook || '',
      instagram: business?.socialMedia?.instagram || '',
      tiktok: business?.socialMedia?.tiktok || '',
    });
  }, [business]);

  useEffect(() => {
    if (business) {
      resetToOriginalValues();
      setAddressValidationState({
        businessAddress: true,
        residentialAddress: true,
      });
    }
  }, [business, resetToOriginalValues]);

  const handleOwnerInfoUpdate = async () => {
    if (!values) return;
    setOwnerInfoState({...ownerInfoState, isSubmitting: true});
    const ownerInfoErrors = validateOwnerInfo(values);

    setErrors((prev) => ({
      ...prev,
      ownerInfo: ownerInfoErrors,
    }));

    if (
      Object.keys(ownerInfoErrors).length === 0 &&
      !errors.ownerInfo?.invalidResidentialAddress &&
      addressValidationState.residentialAddress
    ) {
      try {
        await updateBusiness({
          variables: {
            id: id || '',
            input: {
              owner: {
                firstName: values?.ownerFirstName,
                lastName: values?.ownerLastName,
                idType: values?.idType,
                idPhotos: values?.idPhotos,
                address: prepareAddressInput(
                  values?.ownerAddress ?? '',
                  business?.owner?.address?.location.coordinates || {
                    lat: 0,
                    long: 0,
                  },
                  values?.ownerBuildingName
                ),
              },
            },
          },
        });
        setOwnerInfoState({isEditing: false, isSubmitting: false});
        toast.success('Owner information updated successfully');
      } catch (error) {
        setOwnerInfoState((prev) => ({...prev, isSubmitting: false}));
        console.error('Error updating owner info:', error);
      }
    } else {
      setOwnerInfoState((prev) => ({...prev, isSubmitting: false}));
    }
  };

  const handleBankDetailsUpdate = async () => {
    if (!values) return;
    setBankDetailsState((prev) => ({...prev, isSubmitting: true}));
    const bankInfoErrors = validateBankInfo(values);

    setErrors((prev) => ({
      ...prev,
      bankInfo: bankInfoErrors,
    }));

    if (Object.keys(bankInfoErrors).length === 0) {
      try {
        await updateBusiness({
          variables: {
            id: id || '',
            input: {
              bankAccount: {
                bankId: values?.bankName,
                accountHolder: values?.accountHolder,
                accountNumber: values?.accountNumber,
              },
            },
          },
        });
        setBankDetailsState({isEditing: false, isSubmitting: false});
        toast.success('Bank details updated successfully');
      } catch (error) {
        setBankDetailsState((prev) => ({...prev, isSubmitting: false}));
        console.error('Error updating  bank details', error);
      }
    } else {
      setBankDetailsState((prev) => ({...prev, isSubmitting: false}));
    }
  };

  const handleSocialMediaUpdate = async () => {
    if (!values) return;

    const socialMediaErrors = validateSocialMedia(values);
    setErrors((prev) => ({
      ...prev,
      socialMedia: socialMediaErrors,
    }));

    if (Object.keys(socialMediaErrors).length > 0) {
      Object.values(socialMediaErrors).forEach((error) => toast.error(error));
      return;
    }

    setSocialMediaState((prev) => ({...prev, isSubmitting: true}));

    try {
      await updateBusiness({
        variables: {
          id: id || '',
          input: {
            socialMedia: {
              website: values.website,
              facebook: values.facebook,
              instagram: values.instagram,
              tiktok: values.tiktok,
            },
          },
        },
      });

      setSocialMediaState({isEditing: false, isSubmitting: false});
      setErrors((prev) => ({
        ...prev,
        socialMedia: {},
      }));
      toast.success('Social media information updated successfully');
    } catch (error) {
      setSocialMediaState((prev) => ({...prev, isSubmitting: false}));
      console.error('Error updating social media info:', error);
    }
  };

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        toast.error('Please upload only JPG or PNG files');
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        toast.error('File size must not exceed 5MB');
        return;
      }

      try {
        const res = await uploadMedia({
          variables: {file},
        });

        if (res.errors) {
          toast.error(uploadMediaError?.message);
          return;
        }

        const uploadedId = res.data?.uploadMedia?.id;

        if (uploadedId) {
          setValues((prev) => {
            if (!prev) return undefined;
            return {
              ...prev,
              idFile: file,
              idPhotos: [uploadedId],
              idFileName: file.name,
            };
          });
        } else {
          toast.error('Failed to upload file. Please try again.');
        }
      } catch (error) {
        toast.error('Failed to upload file. Please try again.');
        console.error('Error updloading file:', error);
      }
    }
  };

  const handleFileDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setValues((prev) => {
      if (!prev) return undefined;
      return {
        ...prev,
        idFile: undefined,
        idPhotos: [],
        idFileName: undefined,
      };
    });
  };

  const handleChange = (name: string, value: string) => {
    if (
      name === 'ownerFirstName' ||
      name === 'ownerLastName' ||
      name === 'accountHolder'
    ) {
      setValues((prev) => {
        if (!prev) return undefined;
        return {
          ...prev,
          [name]: value,
        };
      });

      if (hasDigits(value)) {
        if (name === 'accountHolder') {
          setErrors((prev) => ({
            ...prev,
            bankInfo: {
              ...prev.bankInfo,
              accountHolder:
                'Account holder name should not contain numeric values',
            },
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            ownerInfo: {
              ...prev.ownerInfo,
              [name === 'ownerFirstName' ? 'firstName' : 'lastName']:
                `${name === 'ownerFirstName' ? 'First name' : 'Last name'} should not contain numeric values`,
            },
          }));
        }
      } else {
        if (name === 'accountHolder') {
          setErrors((prev) => {
            const updatedBankInfo = {...prev.bankInfo};
            delete updatedBankInfo.accountHolder;
            return {
              ...prev,
              bankInfo: updatedBankInfo,
            };
          });
        } else {
          setErrors((prev) => {
            const updatedOwnerInfo = {...prev.ownerInfo};
            delete updatedOwnerInfo[
              name === 'ownerFirstName' ? 'firstName' : 'lastName'
            ];
            return {
              ...prev,
              ownerInfo: updatedOwnerInfo,
            };
          });
        }
      }
      return;
    }

    setValues((prev) => {
      if (!prev) return undefined;
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handlePhoneChange = (countryCode: string, number: string) => {
    setValues((prev) => {
      if (!prev) return undefined;
      return {
        ...prev,
        phoneNumber: {countryCode, number},
      };
    });
  };

  const handleCancel = () => {
    setValues((prev) => {
      if (!prev) return undefined;
      return {
        ...prev,
        businessName: business?.name || '',
        businessCategory: business?.category?.id || '',
        phoneNumber: {
          countryCode: business?.phoneNumber?.countryCode || '',
          number: business?.phoneNumber?.number || '',
        },
        businessAddress: business?.address?.location.formattedAddress || '',
        businessDescription: business?.description || '',
        buildingName: business?.address?.buildingName || '',
      };
    });
    setErrors((prev) => ({
      ...prev,
      businessInfo: {},
    }));
    setIsEditing(false);
  };

  const handleBusinessAddressChange = (address: string) => {
    setValues((prev) => {
      if (!prev) return undefined;
      return {
        ...prev,
        businessAddress: address,
      };
    });
  };

  const handleResidentialAddressChange = (address: string) => {
    setValues((prev) => {
      if (!prev) return undefined;
      return {
        ...prev,
        ownerAddress: address,
      };
    });
  };

  useEffect(() => {
    if (!values) return;
    const {
      phoneNumber: {number: phoneNumber},
      accountNumber,
    } = values;

    const socialMediaErrors = validateSocialMedia(values);
    setErrors((prev) => ({
      ...prev,
      socialMedia: socialMediaErrors,
    }));

    setErrors((prev) => {
      const newErrors = {...prev};

      if (phoneNumber?.trim()) {
        const isValid = validateKenyanPhoneNumber(phoneNumber.trim());
        newErrors.businessInfo = {
          ...newErrors.businessInfo,
          phoneNumber: isValid ? undefined : 'Phone number is invalid',
        };
      }

      if (accountNumber?.trim()) {
        if (!/^\d*$/.test(accountNumber)) {
          newErrors.bankInfo = {
            ...newErrors.bankInfo,
            accountNumber: 'Please enter valid numeric characters only',
          };
        } else if (accountNumber.length < 10) {
          newErrors.bankInfo = {
            ...newErrors.bankInfo,
            accountNumber: 'Account number must be at least 10 digits',
          };
        } else if (accountNumber.length > 14) {
          newErrors.bankInfo = {
            ...newErrors.bankInfo,
            accountNumber: 'Account number must not exceed 14 digits',
          };
        } else {
          newErrors.bankInfo = {
            ...newErrors.bankInfo,
            accountNumber: undefined,
          };
        }
      }

      return newErrors;
    });
  }, [values]);

  const renderBusinessOwnerSection = () => {
    const handleOwnerCancel = () => {
      setValues((prev) => {
        if (!prev) return undefined;
        return {
          ...prev,
          ownerFirstName: business?.owner?.firstName || '',
          ownerLastName: business?.owner?.lastName || '',
          idType: business?.owner?.idType || VerificationIdType.NationalId,
          idFile: business?.owner?.idPhotos?.[0],
          idFileName:
            business?.owner?.idPhotos?.[0]?.url?.split('/')?.pop() || '',
          idPhotos: business?.owner?.idPhotos?.map((photo) => photo.id) || [],
          ownerAddress:
            business?.owner?.address.location.formattedAddress || '',
          ownerBuildingName: business?.owner?.address.buildingName || '',
        };
      });
      setErrors((prev) => ({
        ...prev,
        ownerInfo: {},
      }));

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      setOwnerInfoState({isEditing: false, isSubmitting: false});
    };

    return (
      <FormSection
        title="Business Owner information"
        hasErrors={Boolean(
          errors?.ownerInfo && Object.keys(errors.ownerInfo).length > 0
        )}
        isEditing={ownerInfoState.isEditing}
        isSubmitting={ownerInfoState.isSubmitting}
        onEdit={() => setOwnerInfoState({...ownerInfoState, isEditing: true})}
        onSave={handleOwnerInfoUpdate}
        onCancel={handleOwnerCancel}>
        <div className="flex flex-col gap-4">
          <div className="flex gap-10">
            <div className="w-[480px]">
              <FormInput
                label="First Name"
                value={values?.ownerFirstName ?? ''}
                onChange={(val) => handleChange('ownerFirstName', val)}
                disabled={!ownerInfoState.isEditing}
                className={cn('w-full text-[14px] text-textGrey font-[500]', {
                  'border-smoothRed': errors.ownerInfo?.firstName,
                  'border-dividerGrey': !errors.ownerInfo?.firstName,
                })}
                error={
                  <RenderErrorMessage error={errors.ownerInfo?.firstName} />
                }
              />
            </div>

            <div className="w-[480px]">
              <FormInput
                label="Last Name"
                value={values?.ownerLastName ?? ''}
                onChange={(val) => handleChange('ownerLastName', val)}
                disabled={!ownerInfoState.isEditing}
                className={cn('w-full text-[14px] text-textGrey font-[500]', {
                  'border-smoothRed': errors.ownerInfo?.lastName,
                  'border-dividerGrey': !errors.ownerInfo?.lastName,
                })}
                error={
                  <RenderErrorMessage error={errors.ownerInfo?.lastName} />
                }
              />
            </div>
          </div>

          <div className="flex gap-10">
            <div className="w-[480px]">
              <FormFieldWrapper label="Identification Document Type" required>
                {ownerInfoState.isEditing ? (
                  <DropDown
                    dropDownPosition="center"
                    show={dropdownIdTypeOpen}
                    setShow={setDropdownIdTypeOpen}
                    className="w-full"
                    actionElement={
                      <div
                        className={cn(
                          'px-[10px] flex flex-row items-center justify-between w-[480px] bg-transparent border-[1px] rounded-[5px] h-[46px] focus:outline-none focus:ring-0 focus:border-surfacePurple overflow-y-auto',
                          {
                            'border-surfacePurple': dropdownIdTypeOpen,
                            'border-dividerGrey': !dropdownIdTypeOpen,
                          }
                        )}>
                        <span className="text-textGrey text-[14px] font-[500]">
                          {values?.idType
                            ? idTypes[values?.idType]
                            : 'Select ID Type'}
                        </span>
                        <DropdownIcon />
                      </div>
                    }>
                    <div className="w-[480px] bg-white rounded-lg">
                      {Object.entries(idTypes).map(([type, label]) => (
                        <div
                          key={type}
                          className="hover:text-surfacePurple cursor-pointer flex items-center bg-white px-4 py-2 border-b-[1px] text-textGrey text-[14px] font-[500] border-dividerGrey"
                          onClick={() => {
                            handleChange('idType', type);
                            setDropdownIdTypeOpen(false);
                          }}>
                          {label}
                        </div>
                      ))}
                    </div>
                  </DropDown>
                ) : (
                  <CustomInput
                    value={
                      idTypes[
                        business?.owner?.idType || VerificationIdType.NationalId
                      ]
                    }
                  />
                )}
              </FormFieldWrapper>
            </div>

            <div className="w-[480px]">
              <FormFieldWrapper
                label="Copy of Valid Proof of Identity"
                required>
                <div className="h-[70px]">
                  {!ownerInfoState.isEditing ? (
                    <a
                      href={business?.owner?.idPhotos?.[0]?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cursor-pointer">
                      <div className="flex items-center gap-2 px-2 opacity-100 border-dashed border-borderColor border-[1px] w-full h-[48px] bg-faintGrey rounded-lg">
                        <ImagePlaceHolderIcon height={30} />
                        <span className="text-textGrey text-[14px] font-[500]">
                          {values?.idFileName ||
                            getFileNameFromUrl(
                              business?.owner?.idPhotos?.[0]?.url || ''
                            )}
                        </span>
                      </div>
                    </a>
                  ) : (
                    <div className="flex items-center gap-2 px-2  border-dashed border-borderColor border-[1px] w-full h-[48px] bg-disabledGrey rounded-lg">
                      <ImagePlaceHolderIcon height={30} />
                      <span className="text-textGrey text-[14px] font-[500] truncate flex-1">
                        {uploadMediaLoading ? (
                          'Uploading...'
                        ) : values?.idFile ? (
                          isFile(values?.idFile) ? (
                            values.idFile.name
                          ) : (
                            values.idFileName || ''
                          )
                        ) : (
                          <p className="text-textGrey text-[14px] font-[500]">
                            Upload an image in PNG or JPG up to 5MB
                          </p>
                        )}
                      </span>
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        accept="image/jpeg,image/jpg,image/png"
                        onChange={handleFileSelect}
                        disabled={!ownerInfoState.isEditing}
                      />
                      {values?.idFile ? (
                        <button
                          onClick={handleFileDelete}
                          disabled={!ownerInfoState.isEditing}>
                          <Delete />
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => fileInputRef.current?.click()}
                          disabled={!ownerInfoState.isEditing}
                          className={cn(
                            'px-3 py-1 border-dividerGrey border text-base hover:shadow-sm hover:shadow-surfacePurple hover:border-lightPurple rounded-lg bg-faintGrey text-fontGrey font-medium',
                            {
                              'opacity-50 cursor-not-allowed':
                                ownerInfoState.isSubmitting,
                            }
                          )}>
                          Browse
                        </button>
                      )}
                    </div>
                  )}
                  {errors.ownerInfo?.id && (
                    <div className="mt-1">
                      <RenderErrorMessage error={errors.ownerInfo.id} />
                    </div>
                  )}
                </div>
              </FormFieldWrapper>
            </div>
          </div>

          <div className="flex gap-10">
            <div className="w-[480px]">
              <RenderAddressField
                type="residential"
                isEditing={ownerInfoState.isEditing}
                currentValue={values?.ownerAddress ?? ''}
                onAddressChange={handleResidentialAddressChange}
                errors={errors}
                setErrors={setErrors}
                setAddressValidationState={setAddressValidationState}
              />
            </div>

            <div className="w-[480px]">
              <FormInput
                label="Complex/ Building Name"
                value={values?.ownerBuildingName ?? ''}
                onChange={(val) => handleChange('ownerBuildingName', val)}
                disabled={!ownerInfoState.isEditing}
                className="w-full text-textGrey text-[14px] font-[500]"
              />
            </div>
          </div>
        </div>
      </FormSection>
    );
  };

  const renderBankDetailsSection = () => {
    const handleBankCancel = () => {
      setValues((prev) => {
        if (!prev) return undefined;
        return {
          ...prev,
          bankName: business?.bankAccount?.bank.id || '',
          accountHolder: business?.bankAccount?.accountHolder || '',
          accountNumber: business?.bankAccount?.accountNumber || '',
        };
      });

      setErrors((prev) => ({
        ...prev,
        bankInfo: {},
      }));

      setBankDetailsState({isEditing: false, isSubmitting: false});
    };

    return (
      <FormSection
        title="Bank Account Details"
        isEditing={bankDetailsState.isEditing}
        isSubmitting={bankDetailsState.isSubmitting}
        hasErrors={Boolean(errors?.bankInfo?.accountHolder)}
        onEdit={() =>
          setBankDetailsState({...bankDetailsState, isEditing: true})
        }
        onSave={handleBankDetailsUpdate}
        onCancel={handleBankCancel}>
        <div className="flex flex-col">
          <div className="flex gap-10">
            <div className="w-[480px]">
              <FormFieldWrapper label="Bank Name" required>
                {bankDetailsState.isEditing ? (
                  <div className="w-[480px]h-[70px]">
                    <DropDown
                      dropDownPosition="center"
                      show={dropdownOpen}
                      setShow={setDropdownOpen}
                      className={cn(
                        'px-[10px] flex w-full bg-transparent border rounded-[5px] max-h-[250px] focus:outline-none focus:ring-0 overflow-y-auto',
                        {
                          'border-surfacePurple': dropdownOpen,
                          'border-dividerGrey': !dropdownOpen,
                          'border-smoothRed': errors.businessInfo?.category,
                        }
                      )}
                      actionElement={
                        <div
                          className={cn(
                            'px-[10px] flex flex-row items-center justify-between w-[480px] bg-transparent border-[1px] rounded-[5px] h-[46px]',
                            {
                              'border-surfacePurple': dropdownOpen,
                              'border-dividerGrey': !dropdownOpen,
                            }
                          )}>
                          <span className="text-textGrey text-[14px] font-[500]">
                            {banks?.find((x) => x.id === values?.bankName)
                              ?.name || 'Select Bank'}
                          </span>
                          <DropdownIcon />
                        </div>
                      }>
                      <div className="py-2 w-[480px]">
                        {banks?.map((bank) => (
                          <div
                            key={bank.id}
                            className="hover:text-surfacePurple cursor-pointer flex items-center bg-white px-4 py-2 border-b-[1px] border-dividerGrey"
                            onClick={() => {
                              handleChange('bankName', bank.id);
                              setDropdownOpen(false);
                            }}>
                            <p className="text-[14px] hover:text-textPurple text-textGrey font-[500]">
                              {bank.name}
                            </p>
                          </div>
                        ))}
                      </div>
                    </DropDown>
                  </div>
                ) : (
                  <CustomInput value={business?.bankAccount?.bank.name} />
                )}
              </FormFieldWrapper>
            </div>

            <div className="w-[480px]">
              <FormInput
                label="Account Holder Name"
                required
                value={values?.accountHolder ?? ''}
                onChange={(val) => handleChange('accountHolder', val)}
                disabled={!bankDetailsState.isEditing}
                className={cn('w-full text-textGrey text-[14px] font-[500]', {
                  'border-smoothRed': errors.bankInfo?.accountHolder,
                  'border-dividerGrey': !errors.bankInfo?.accountHolder,
                })}
                error={
                  <RenderErrorMessage error={errors.bankInfo?.accountHolder} />
                }
              />
            </div>
          </div>
          <div className="flex gap-10">
            <div className="w-[480px]">
              <FormInput
                label="Account Number"
                required
                value={values?.accountNumber ?? ''}
                onChange={(val) =>
                  handleChange('accountNumber', val.replace(/\D/g, ''))
                }
                disabled={!bankDetailsState.isEditing}
                className={cn('w-full text-textGrey text-[14px] font-[500]', {
                  'border-smoothRed': errors.bankInfo?.accountNumber,
                  'border-dividerGrey': !errors.bankInfo?.accountNumber,
                })}
                error={
                  <RenderErrorMessage error={errors.bankInfo?.accountNumber} />
                }
              />
            </div>
          </div>
        </div>
      </FormSection>
    );
  };
  const renderSocialMediaSection = () => {
    const handleSocialMediaCancel = () => {
      setValues((prev) => {
        if (!prev) return undefined;
        return {
          ...prev,
          website: business?.socialMedia?.website || '',
          facebook: business?.socialMedia?.facebook || '',
          instagram: business?.socialMedia?.instagram || '',
          tiktok: business?.socialMedia?.tiktok || '',
        };
      });
      setErrors((prev) => ({
        ...prev,
        socialMedia: {},
      }));

      setSocialMediaState({isEditing: false, isSubmitting: false});
    };
    return (
      <FormSection
        title="Social Media"
        isEditing={socialMediaState.isEditing}
        isSubmitting={socialMediaState.isSubmitting}
        onEdit={() =>
          setSocialMediaState({...socialMediaState, isEditing: true})
        }
        onSave={handleSocialMediaUpdate}
        onCancel={handleSocialMediaCancel}>
        <div className="flex flex-col">
          <div className="flex gap-10">
            <FormInput
              label="Website"
              value={values?.website || ''}
              onChange={(val) => handleChange('website', val)}
              disabled={!socialMediaState.isEditing}
              placeholder={
                socialMediaState.isEditing ? 'https://www.site.com' : ''
              }
              className="w-[480px] text-textGrey text-[14px] font-[500]"
              error={<RenderErrorMessage error={errors.socialMedia?.website} />}
            />
            <div className="flex flex-col">
              <Label className="font-medium text-textGrey sm:text-[15px] md:text-[16px]">
                Socials
              </Label>
              <FormInput
                value={values?.facebook ?? ''}
                onChange={(val) => handleChange('facebook', val)}
                leftElement={<FacebookIcon />}
                placeholder={
                  socialMediaState.isEditing
                    ? 'https://www.facebook.com/username'
                    : ''
                }
                disabled={!socialMediaState.isEditing}
                className="pl-[50px] w-[480px] text-textGrey text-[14px] font-[500]"
                error={
                  <RenderErrorMessage error={errors.socialMedia?.facebook} />
                }
              />
              <FormInput
                value={values?.instagram ?? ''}
                onChange={(val) => handleChange('instagram', val)}
                leftElement={<InstagramIcon />}
                disabled={!socialMediaState.isEditing}
                placeholder={
                  socialMediaState.isEditing
                    ? 'https://www.instagram.com/username'
                    : ''
                }
                className="pl-[50px] w-[480px] text-textGrey text-[14px] font-[500]"
                error={
                  <RenderErrorMessage error={errors.socialMedia?.instagram} />
                }
              />
              <FormInput
                value={values?.tiktok ?? ''}
                onChange={(val) => handleChange('tiktok', val)}
                leftElement={<TiktokIcon />}
                disabled={!socialMediaState.isEditing}
                placeholder={
                  socialMediaState.isEditing
                    ? 'https://www.tiktok.com/@username'
                    : ''
                }
                className="pl-[50px] w-[480px] text-textGrey text-[14px] font-[500]"
                error={
                  <RenderErrorMessage error={errors.socialMedia?.tiktok} />
                }
              />
            </div>
          </div>
        </div>
      </FormSection>
    );
  };

  return (
    <div>
      <Navbar
        title="Business Profile Details"
        omitSearch
        breadcrumbs={breadcrumbs}
      />
      {loading ? (
        <CustomSpinner />
      ) : (
        <>
          <div className="m-6 border border-dividerGrey p-8 rounded-lg h-[136px] flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="relative inline-flex h-[56px] w-[56px] items-center justify-center rounded-full bg-mediumPurple text-xl font-medium text-white">
                {initials}
              </div>
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                {business?.name}
              </h1>
            </div>
            <div className="flex gap-10 items-center">
              <div className="flex gap-2 items-center">
                <StatusDot status={business?.complianceStatus} />
                <div>
                  <h1 className="text-foggy font-semibold text-xs">
                    Compliance Status
                  </h1>
                  {business && (
                    <h1 className="font-semibold text-base">
                      {complianceStatus[business.complianceStatus]}
                    </h1>
                  )}
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <h1 className="text-foggy font-semibold text-xs">
                    Account Name
                  </h1>
                  <h1 className="font-semibold text-base">{userName}</h1>
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <h1 className="text-foggy font-semibold text-xs">
                    Created Date
                  </h1>
                  <h1 className="font-semibold text-base">
                    {formatDate(new Date(business?.createdAt as string), {
                      withTime: true,
                      withNumericDate: true,
                    })}
                  </h1>
                </div>
              </div>

              <Button className="w-[235px] h-[44px] font-semibold text-white text-sm bg-surfacePurple border rounded-lg">
                Upload Compliance Status
              </Button>
            </div>
          </div>
          <div className="m-6 border border-dividerGrey p-8 rounded-lg flex items-center justify-between">
            <div className="flex gap-5 flex-col w-full">
              <h3 className="font-semibold text-2xl whitespace-nowrap">
                Balances
              </h3>
              <div className="flex gap-3">
                {Object.values(CurrencyCode).map((currency) => (
                  <div
                    key={currency}
                    onClick={() => setSelectedCurrency(currency)}
                    className={cn(
                      'flex justify-between group w-40 p-3 rounded-md cursor-pointer transition-colors bg-transparent border border-[#D6D4DE] hover:bg-lightPurple/50 hover:border-mediumPurple/40',
                      {
                        'bg-[#5E1899] hover:bg-[#5E1899] border-none':
                          currency === selectedCurrency,
                      }
                    )}>
                    <div className="flex flex-col gap-[4px]">
                      <p
                        className={cn(
                          'text-[#6A6077] group-hover:text-mediumPurple text-sm font-semibold',
                          {
                            'text-white group-hover:text-white':
                              currency === selectedCurrency,
                          }
                        )}>
                        {readableCurrencyCodeInfo(currency).name}
                      </p>
                      <p
                        className={cn(
                          'text-[#B2ABBF] group-hover:text-mediumPurple/80 text-xs font-light',
                          {
                            'text-[#CCB1FF] group-hover:text-[#CCB1FF]':
                              currency === selectedCurrency,
                          }
                        )}>
                        {readableCurrencyCodeInfo(currency).fullName}
                      </p>
                    </div>
                    <div
                      className={cn(
                        'flex justify-center items-center border border-[#B2ABBF] h-4 w-4 rounded-full',
                        {
                          'border-white': currency === selectedCurrency,
                        }
                      )}>
                      <div
                        className={cn('hidden bg-white h-2 w-2 rounded-full', {
                          'flex flex-col': currency === selectedCurrency,
                        })}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex w-full">
                <div className="flex flex-1 p-8 border border-[#D6D4DE] rounded-md rounded-r-none">
                  <div className="flex gap-5">
                    <AvailableBalanceIcon />
                    <div className="flex flex-col">
                      <p className="text-xs text-[#7F738E]">
                        Available Balance
                      </p>
                      <p className="font-semibold text-2xl text-[#3A3441]">
                        {readableCurrencyCodeInfo(selectedCurrency).name}{' '}
                        {businessData?.business?.balance
                          .find((bal) => bal.currency === selectedCurrency)
                          ?.available.toLocaleString('en-KE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) || '0.00'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-1 p-8 border border-[#D6D4DE] border-l-0 rounded-md rounded-l-none">
                  <div className="flex gap-5">
                    <TotalBalanceIcon />
                    <div className="flex flex-col">
                      <p className="text-xs text-[#7F738E]">Total Balance</p>
                      <p className="font-semibold text-2xl text-[#3A3441]">
                        {readableCurrencyCodeInfo(selectedCurrency).name}{' '}
                        {businessData?.business?.balance
                          .find((bal) => bal.currency === selectedCurrency)
                          ?.total.toLocaleString('en-KE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) || '0.00'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FormSection
            title="Business Information"
            isEditing={isEditing}
            isSubmitting={isSubmitting}
            onEdit={() => setIsEditing(true)}
            onSave={handleBusinessInfoUpdate}
            onCancel={handleCancel}>
            <div className="flex flex-col gap-4">
              <div className="flex gap-10">
                <div className="w-[480px]">
                  <FormInput
                    label="Business Name"
                    value={values?.businessName ?? ''}
                    onChange={(val) => handleChange('businessName', val)}
                    disabled={!isEditing}
                    className={cn(
                      'w-full text-[14px] text-textGrey font-[500]',
                      {
                        'border-smoothRed': errors.businessInfo?.businessName,
                        'border-dividerGrey':
                          !errors.businessInfo?.businessName,
                      }
                    )}
                    error={
                      <RenderErrorMessage
                        error={errors.businessInfo?.businessName}
                      />
                    }
                  />
                </div>

                <div className="w-[480px]">
                  <FormFieldWrapper label="Business Category" required>
                    {isEditing ? (
                      <DropDown
                        dropDownPosition="center"
                        show={dropdownCategoryOpen}
                        setShow={setDropdownCategoryOpen}
                        className={cn(
                          'px-[10px] flex max-md:w-[86vw] w-[480px] bg-transparent border-[1px] rounded-[5px] max-h-[250px] focus:outline-none focus:ring-0 focus:border-surfacePurple overflow-y-auto',
                          {
                            'border-surfacePurple': dropdownCategoryOpen,
                            'border-dividerGrey': !dropdownCategoryOpen,
                            'border-smoothRed': errors.businessInfo?.category,
                          }
                        )}
                        actionElement={
                          <div
                            className={cn(
                              'px-[10px] flex flex-row items-center justify-between w-[480px] bg-transparent border-[1px] rounded-[5px] h-[46px]',

                              {
                                'border-surfacePurple': dropdownCategoryOpen,
                                'border-dividerGrey': !dropdownCategoryOpen,
                              }
                            )}>
                            <span className="text-[14px] text-textGrey font-[500]">
                              {categoriesData?.businessCategories.find(
                                (cat) => cat.id === values?.businessCategory
                              )?.name || 'Select Category'}
                            </span>
                            <DropdownIcon />
                          </div>
                        }>
                        <div className="w-[480px] bg-white rounded-lg">
                          {categories?.map((category) => (
                            <div
                              key={category.id}
                              className="hover:text-surfacePurple cursor-pointer flex items-center bg-white px-4 py-2 border-b-[1px] border-dividerGrey"
                              onClick={() => {
                                handleChange('businessCategory', category.id);
                                setDropdownCategoryOpen(false);
                              }}>
                              <p className="text-[14px] hover:text-textPurple text-grey font-[500]">
                                {category.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </DropDown>
                    ) : (
                      <CustomInput value={business?.category?.name} />
                    )}
                  </FormFieldWrapper>
                </div>
              </div>

              <div className="flex gap-10">
                <div className="w-[480px]">
                  <label className="font-medium text-grey text-[15px] mb-2 block">
                    Business Phone Number
                  </label>
                  <div className="h-[70px]">
                    <CustomPhoneInput
                      countryCode={values?.phoneNumber.countryCode ?? ''}
                      phoneNumber={values?.phoneNumber.number ?? ''}
                      onChange={handlePhoneChange}
                      isDisabled={!isEditing}
                      error={
                        errors.businessInfo?.phoneNumber ? (
                          <RenderErrorMessage
                            error={errors.businessInfo.phoneNumber}
                          />
                        ) : null
                      }
                    />
                  </div>
                </div>

                <div className="w-[480px]">
                  <RenderAddressField
                    type="business"
                    isEditing={isEditing}
                    currentValue={values?.businessAddress ?? ''}
                    onAddressChange={handleBusinessAddressChange}
                    errors={errors}
                    setErrors={setErrors}
                    setAddressValidationState={setAddressValidationState}
                  />
                </div>
              </div>

              <div className="flex gap-10">
                <div className="w-[480px]">
                  <FormInput
                    label="Business Description"
                    value={values?.businessDescription ?? ''}
                    onChange={(val) => handleChange('businessDescription', val)}
                    disabled={!isEditing}
                    className="w-full text-[14px] text-textGrey font-[500]"
                    error={
                      <RenderErrorMessage
                        error={errors.businessInfo?.description}
                      />
                    }
                  />
                </div>

                <div className="w-[480px]">
                  <FormInput
                    label="Complex/ Building Name"
                    value={values?.buildingName ?? ''}
                    onChange={(val) => handleChange('buildingName', val)}
                    disabled={!isEditing}
                    className="w-full text-[14px] text-textGrey font-[500]"
                  />
                </div>
              </div>
            </div>
          </FormSection>
          {renderBusinessOwnerSection()}
          {renderBankDetailsSection()}
          {renderSocialMediaSection()}
        </>
      )}
    </div>
  );
};

export default ProfileDetails;
