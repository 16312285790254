import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import CustomSpinner from '../../components/common/CustomSpinner';
import EmptyState from '../../components/common/EmptyState';
import Navbar from '../../components/common/Navbar';
import Paginator from '../../components/common/Paginator';
import CommonTable from '../../components/common/table';
import {useGetOrdersQuery} from '../../generated';
import formatDate from '../../utils/reusablefunctions/formatDate';
import formatPaymentMode from '../../utils/reusablefunctions/formatPaymentMode';
import getPaymentIcon from '../../utils/reusablefunctions/getPaymentIcon';
import {TableColumnType, TableDataType} from '../../utils/types/table.types';

const columns: TableColumnType[] = [
  'transaction_id',
  'amount',
  'payment_method',
  'business_name',
  'payment_date',
  'payment_status',
];

const PAGE_SIZE = 16;

const Payments = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(1);
  const [currentCursor, setCurrentCursor] = useState<string | null>(null);
  const [cursorStack, setCursorStack] = useState<string[]>([]);

  const {data: payments, loading: paymentsLoading} = useGetOrdersQuery({
    variables: {
      input: {
        cursor: {
          first: PAGE_SIZE,
          after: currentCursor,
        },
      },
    },
  });

  const transformedData = React.useMemo(() => {
    if (!payments?.orders?.edges) return [];

    return payments?.orders?.edges.map(({node}) => ({
      id: node.id,
      transaction_id: `#${node?.reference || ''}`,
      amount:
        node.cost.currencyCode +
        ' ' +
        new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(Number(node.cost.amountInCents / 1000)),

      payment_method: [
        getPaymentIcon(node?.paymentMode || '', node?.card?.issuer),
        formatPaymentMode(node?.paymentMode || ''),
      ],

      business_name: node.paymentLink.business.name || '',

      payment_date: node.createdAt
        ? formatDate(new Date(node?.createdAt as string), {
            withTime: true,
            withNumericDate: true,
          })
        : '---',

      payment_status: [node?.state || ''],
    })) as TableDataType[];
  }, [payments]);

  const loadMore = useCallback(
    async (next: boolean) => {
      try {
        if (next && payments?.orders?.pageInfo?.hasNextPage) {
          const nextCursor = payments?.orders?.pageInfo?.endCursor;
          if (nextCursor) {
            if (currentCursor) {
              setCursorStack((prev) => [...prev, currentCursor]);
            }
            setCurrentCursor(nextCursor);
            setCount((prev) => prev + 1);
          }
        } else if (!next && count > 1) {
          if (cursorStack.length > 0) {
            const previousCursor = cursorStack[cursorStack.length - 1];
            if (previousCursor) {
              setCursorStack((prev) => prev.slice(0, -1));
              setCurrentCursor(previousCursor);
              setCount((prev) => prev - 1);
            }
          } else {
            setCurrentCursor(null);
            setCount(1);
          }
        }
      } catch (error) {
        console.error('Error loading more businesses:', error);
      }
    },
    [payments, currentCursor, cursorStack, count]
  );

  const handleViewDetails = (id: string) => {
    navigate(`/payments/payment-details/${id}`);
  };

  return (
    <div>
      <Navbar omitSearch title="Payments" />
      <div className="px-6 py-4 h-full overflow-y-auto">
        {paymentsLoading && !payments ? (
          <CustomSpinner />
        ) : payments?.orders.edges.length ? (
          <>
            <div className="flex flex-col h-full">
              <h2 className="text-2xl px-2 font-semibold">Payments List</h2>

              <div className="bg-white rounded-lg flex-1">
                <CommonTable
                  columns={columns}
                  data={transformedData}
                  prettify
                  viewRecord={handleViewDetails}
                />
              </div>

              {payments?.orders.pageInfo.entries > PAGE_SIZE && (
                <div className="mt-auto h-[60px] p-6">
                  <Paginator
                    count={count}
                    pageInfo={{
                      ...payments.orders.pageInfo,
                      hasPrevPage: count > 1,
                    }}
                    loadMore={loadMore}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <EmptyState
            title="It's lonely out here"
            description="It seems like no payments have been processed yet. Once payments are made to merchant accounts, they will appear here for easy access and management."
          />
        )}
      </div>
    </div>
  );
};

export default Payments;
