import {ApolloError} from '@apollo/client';
import {
  Button,
  Modal,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalTitle,
  ModalTrigger,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  toast,
} from '@cashiaApp/web-components';
import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import CustomPhoneInput from '../../components/common/CustomPhoneInput';
import CustomSpinner from '../../components/common/CustomSpinner';
import EditButton from '../../components/common/EditButton';
import FormInput from '../../components/common/FormInput';
import Navbar from '../../components/common/Navbar';
import StatusDot from '../../components/common/StatusDot';
import {
  useGetUserQuery,
  UserRole,
  useUpdateUserDetailsMutation,
} from '../../generated';
import userInitials from '../../utils/getNameInitials';
import {hasDigits, isValidEmail} from '../../utils/helper/validation';
import {cn} from '../../utils/reusablefunctions';
import capitalizeFirstLetter from '../../utils/reusablefunctions/capitalizeFirstLetter';
import RenderErrorMessage from '../../utils/reusablefunctions/errorMessage';
import formatDate from '../../utils/reusablefunctions/formatDate';
import validateKenyanPhoneNumber from '../../utils/validatePhoneNumber';

const breadcrumbs = [
  {
    name: 'Users',
    location: '/users',
  },
  {
    name: 'User Profile Details',
  },
];

const roleTypes = {
  [UserRole.Admin]: 'Administrator',
  [UserRole.Compliance]: 'Compliance',
  [UserRole.Finance]: 'Finance',
  [UserRole.Support]: 'Support',
  [UserRole.Operations]: 'Operations',
  [UserRole.Merchant]: '',
};

type FormValues = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  role?: UserRole | string;
};

interface FormErrors {
  [key: string]: string;
}

export default function UserDetails() {
  const {id} = useParams<{id: string}>();
  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState<FormValues>();
  const [errors, setErrors] = useState<FormErrors>({});
  const [updateUser, {loading: updateLoading}] = useUpdateUserDetailsMutation();

  const {data, loading, refetch} = useGetUserQuery({
    variables: {
      id: id || '',
    },
    skip: !id,
  });

  const initialValues = useMemo(
    () => ({
      firstName: data?.user?.firstName || '',
      lastName: data?.user?.lastName || '',
      email: data?.user?.email || '',
      phoneNumber: data?.user?.phone?.number || '',
      role: data?.user?.roles?.[0] || '',
    }),
    [data]
  );

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const initials = useMemo(
    () =>
      data
        ? userInitials(data?.user?.firstName || '', data?.user?.lastName || '')
        : '',
    [data]
  );

  const concatRoles = useMemo(
    () => data?.user?.roles.map((role) => roleTypes[role])?.join(', '),
    [data]
  );

  const userDetails = useMemo(
    () => [
      {
        title: 'Status',
        status: data?.user?.status,
        description: capitalizeFirstLetter(
          (data?.user?.status || '').toLowerCase()
        ),
      },
      {
        title: 'Created Date',
        description: formatDate(new Date(data?.user?.createdAt as string), {
          withTime: true,
          withNumericDate: true,
          with24Hour: true,
          withSeconds: true,
        }),
      },
      {
        title: 'Last Login Date',
        description: '15/12/24, 07:10:19',
      },
    ],
    [data]
  );

  const handleChange = (field: string, value: string) => {
    const _errors: FormErrors = {...errors};
    setValues({
      ...values,
      [field]: value,
    });
    switch (field) {
      case 'email':
        if (value && !isValidEmail(value)) {
          _errors.email = 'Email address is invalid';
        } else {
          _errors.email = '';
        }
        if (value === '') {
          _errors.email = 'Email is required';
        }
        break;
      case 'phoneNumber':
        if (value?.trim() && !validateKenyanPhoneNumber(value.trim())) {
          _errors.phoneNumber = 'Phone number is invalid';
        } else {
          _errors.phoneNumber = '';
        }
        if (value === '') {
          _errors.phoneNumber = 'Phone number is required';
        }
        break;
      case 'firstName':
        if (value === '') {
          _errors.firstName = 'First name is required';
        } else if (value && hasDigits(value)) {
          _errors.firstName = 'First name can only contain letters';
        } else {
          _errors.firstName = '';
        }
        break;
      case 'lastName':
        if (value === '') {
          _errors.lastName = 'Last name is required';
        } else if (value && hasDigits(value)) {
          _errors.lastName = 'First name can only contain letters';
        } else {
          _errors.lastName = '';
        }
        break;
      default:
        break;
    }
    setErrors(_errors);
  };

  const handleSubmit = () => {
    if (!values || Object.values(errors).filter(Boolean).length > 0) return;
    const newRole = Object.entries(roleTypes).find(
      ([_, value]) => value === values.role
    )?.[0] as UserRole;
    updateUser({
      variables: {
        input: {
          id: id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: {
            number: values.phoneNumber || '',
            countryCode: data?.user?.phone?.countryCode || '',
          },
          roles: newRole ? [newRole] : data?.user.roles,
        },
      },
    })
      .then((res) => {
        if (res.data?.updateUser) {
          setIsEditing(false);
          void refetch();
        }
      })
      .catch((e: ApolloError) => {
        toast.error(`Error: ${e.message}`);
        setIsEditing(true);
      });
  };

  return (
    <>
      <Navbar
        title="User Profile Details"
        omitSearch
        breadcrumbs={breadcrumbs}
      />

      {loading ? (
        <CustomSpinner />
      ) : (
        <div className="h-[calc(100vh-64px)] overflow-y-auto px-2 py-6 bg-displayGrey ">
          <div className="mx-6 border border-dividerGrey p-8 rounded-lg h-[136px] flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="relative inline-flex h-[56px] w-[56px] items-center justify-center rounded-full bg-mediumPurple text-xl font-medium text-white  ">
                {initials}
              </div>

              <h1 className="font-semibold text-2xl whitespace-nowrap">
                {data?.user?.firstName || ''} {data?.user?.lastName || ''}
              </h1>
            </div>
            <div className="flex gap-20 pr-40">
              {userDetails.map((detail) => (
                <div className="flex gap-2 items-center">
                  {detail.status && <StatusDot status={detail.status} />}

                  <div>
                    <h1 className="text-foggy font-semibold text-xs">
                      {detail?.title}
                    </h1>
                    <h1 className="font-semibold text-base">
                      {detail.description}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="m-6 border p-8 flex-col border-dividerGrey rounded-lg h-auto flex">
            <div className="flex gap-40 justify-between items-center w-full mb-6">
              <h1 className="font-semibold text-2xl">User Information</h1>
              {isEditing ? (
                <Modal>
                  <div className="flex gap-3">
                    <ModalTrigger asChild>
                      <Button
                        disabled={
                          Object.values(errors).filter(Boolean).length > 0
                        }
                        className="w-[134px] h-[44px] whitespace-nowrap border-dividerGrey font-semibold border text-base rounded-lg bg-surfacePurple hover:bg-mediumPurple text-white">
                        Save Changes
                      </Button>
                    </ModalTrigger>

                    <Button
                      onClick={() => {
                        setValues(initialValues);
                        setIsEditing(false);
                        setErrors({});
                      }}
                      className="w-[77px] h-[44px] border-dividerGrey font-semibold border text-base hover:shadow-sm hover:border-1
                      hover:border-mediumPurple rounded-lg bg-faintGrey text-fontGrey">
                      Cancel
                    </Button>
                  </div>
                  <ModalContent>
                    <ModalTitle>Save Changes</ModalTitle>
                    <ModalDescription>
                      Please review your updates to ensure all information is
                      correct. This will permanently update your account with
                      new details.
                    </ModalDescription>
                    <ModalDescription>
                      Would you like to proceed?
                    </ModalDescription>
                    <div className="flex justify-between">
                      <ModalClose asChild>
                        <Button
                          className="border border-dividerGrey hover:border-1
        hover:border-mediumPurple bg-faintGrey text-black rounded-lg p-2.5">
                          Cancel
                        </Button>
                      </ModalClose>
                      <ModalClose asChild>
                        <Button
                          className="whitespace-nowrap border-dividerGrey font-semibold border text-base rounded-lg bg-surfacePurple hover:bg-mediumPurple text-white"
                          onClick={handleSubmit}>
                          {updateLoading ? <CustomSpinner /> : '  Save Changes'}
                        </Button>
                      </ModalClose>
                    </div>
                  </ModalContent>
                </Modal>
              ) : (
                <EditButton onButtonClick={() => setIsEditing(true)} />
              )}
            </div>

            <form onSubmit={(e) => e.preventDefault()}>
              <div className=" flex text-grey gap-6">
                <div>
                  <FormInput
                    label="First Name"
                    required
                    className="w-[480px]"
                    disabled={!isEditing}
                    name="firstName"
                    maxLength={60}
                    value={values?.firstName?.trim() || ''}
                    onChange={(firstName) =>
                      handleChange('firstName', firstName)
                    }
                    error={<RenderErrorMessage error={errors.firstName} />}
                  />
                </div>
                <div>
                  <FormInput
                    label="Last Name"
                    name="lastName"
                    required
                    maxLength={60}
                    className="w-[480px]"
                    disabled={!isEditing}
                    value={values?.lastName?.trim() || ''}
                    onChange={(lastName) => handleChange('lastName', lastName)}
                    error={<RenderErrorMessage error={errors.lastName} />}
                  />
                </div>
              </div>
              <div className=" flex text-grey gap-6">
                <div>
                  <FormInput
                    label="Email"
                    required
                    className="w-[480px]"
                    disabled={!isEditing}
                    value={values?.email || ''}
                    onChange={(email) => handleChange('email', email)}
                    error={<RenderErrorMessage error={errors.email} />}
                  />
                </div>
                <div>
                  <label className="sm:font-medium md:font-medium text-textGrey sm:text-[15px] md:text-[16px] text-sm font-bold flex mb-1 ml-1 items-center">
                    Phone Number *
                  </label>

                  <CustomPhoneInput
                    countryCode={data?.user?.phone?.countryCode || ''}
                    phoneNumber={values?.phoneNumber || ''}
                    isDisabled={!isEditing}
                    onChange={(_, phone) => {
                      handleChange('phoneNumber', phone);
                    }}
                    error={<RenderErrorMessage error={errors.phoneNumber} />}
                  />
                </div>
              </div>
              <div>
                {isEditing ? (
                  <div className="w-[480px] text-gray">
                    <p className="text-gray font-medium mb-1">Role *</p>
                    <Select
                      onValueChange={(role) => {
                        setValues({
                          ...values,
                          role,
                        });
                      }}>
                      <SelectTrigger
                        className={cn(
                          'w-full h-12 text-base font-medium border-lightGrey focus:outline-none focus:ring-0',
                          values?.role
                            ? 'text-gray'
                            : 'focus:text-surfacePurple focus:bg-lightBlue focus:border-surfacePurple'
                        )}>
                        <SelectValue
                          className={cn(
                            'font-medium',
                            values?.role
                              ? 'text-gray'
                              : 'focus:text-surfacePurple'
                          )}
                          placeholder={concatRoles}
                        />
                      </SelectTrigger>
                      <SelectContent className="items-start text-sm border-lightGrey">
                        <SelectGroup className="items-start ml-[-22px]">
                          {Object.values(roleTypes)
                            .filter((x) => x !== '')
                            .map((role, index) => (
                              <React.Fragment key={index}>
                                <SelectItem
                                  value={role}
                                  className="text-left font-medium text-gray h-[40px]">
                                  {role}
                                </SelectItem>
                                {index !==
                                  Object.values(roleTypes).filter(
                                    (x) => x !== ''
                                  ).length -
                                    1 && <hr className="border-lightGrey" />}
                              </React.Fragment>
                            ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                ) : (
                  <FormInput
                    label="Role"
                    name="role"
                    className="w-[480px]"
                    value={concatRoles}
                    disabled
                    required
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
