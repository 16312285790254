import {Button} from '@cashiaApp/web-components';
import React from 'react';

import {ReactComponent as Plus} from '../../assets/icons/add-square.svg';
import {ReactComponent as Emoji} from '../../assets/icons/emoji_lonely.svg';

type EmptyStateProps = {
  title: string;
  description: string;
  action?: {
    label: string;
    onClick: () => void;
    icon?: React.ReactNode;
  };
  icon?: React.ReactNode;
  className?: string;
};

const EmptyState = ({
  title,
  description,
  action,
  icon,
  className = '',
}: EmptyStateProps) => {
  return (
    <div
      className={`flex flex-col items-center justify-center h-[calc(100vh-200px)] ${className}`}>
      <div className="w-16 h-16 bg-yellow-100 rounded-full flex items-center justify-center mb-4">
        {icon || <Emoji />}
      </div>
      <h3 className="text-4xl font-bold mb-2 text-black">{title}</h3>
      <p className="text-greyBlueish text-center w-[640px] text-base font-medium mb-6">
        {description}
      </p>
      {action && (
        <Button
          onClick={action.onClick}
          className="bg-surfacePurple hover:bg-surfacePurple text-white">
          {action.icon || <Plus className="w-4 h-4 mr-2" />}
          {action.label}
        </Button>
      )}
    </div>
  );
};

export default EmptyState;
