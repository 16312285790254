import {
  Modal,
  ModalTrigger,
  Button,
  ModalContent,
  ModalTitle,
  ModalDescription,
} from '@cashiaApp/web-components';
import {useState} from 'react';

import EditButton from '../../../components/common/EditButton';
import Spinner from '../../../components/common/Spinner';
import {cn} from '../../../utils/reusablefunctions';
import {FormSectionProps} from '../types/types';

interface SaveChangesModalProps {
  isSubmitting: boolean;
  onSave: () => Promise<void>;
  onCancel: () => void;
  title?: string;
  description?: string;
  hasErrors?: boolean;
}

const SaveChangesModal: React.FC<SaveChangesModalProps> = ({
  isSubmitting,
  onSave,
  onCancel,
  title,
  description,
  hasErrors,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSave = async () => {
    try {
      await onSave();
      setIsOpen(false);
    } catch (error) {
      console.error('Error during save:', error);
    }
  };

  return (
    <Modal open={isOpen}>
      <div className="flex gap-3">
        <ModalTrigger asChild>
          <Button
            onClick={() => setIsOpen(true)}
            disabled={hasErrors}
            className={cn(
              'w-[134px] h-[44px] whitespace-nowrap border-dividerGrey font-semibold border text-base rounded-lg bg-surfacePurple text-white',
              {'opacity-50 cursor-not-allowed': hasErrors}
            )}>
            Save Changes
          </Button>
        </ModalTrigger>

        <Button
          onClick={onCancel}
          className="w-[77px] h-[44px] border-dividerGrey font-semibold border text-base hover:shadow-sm hover:border-1
          hover:border-mediumPurple rounded-lg bg-faintGrey text-fontGrey">
          Cancel
        </Button>
      </div>

      <ModalContent>
        <ModalTitle className="text-2xl font-semibold text-black mb-4">
          {title}
        </ModalTitle>
        <ModalDescription>{description}</ModalDescription>
        <div className="flex justify-between">
          <Button
            onClick={() => setIsOpen(false)}
            disabled={isSubmitting}
            className="border border-dividerGrey hover:border-1
            hover:border-mediumPurple bg-faintGrey text-black rounded-lg p-2.5">
            Cancel
          </Button>
          <Button
            className="whitespace-nowrap border-dividerGrey font-semibold border text-base rounded-lg bg-surfacePurple hover:bg-mediumPurple text-white"
            onClick={handleSave}
            disabled={isSubmitting}>
            {isSubmitting ? <Spinner className="fill-white" /> : 'Save Changes'}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export const FormSection: React.FC<FormSectionProps> = ({
  title,
  isEditing,
  isSubmitting,
  onEdit,
  onSave,
  onCancel,
  children,
  hasErrors,
}) => {
  return (
    <div className="m-6 border border-dividerGrey p-8 rounded-lg h-auto">
      <div className="mb-2 flex justify-between">
        <h1 className="font-semibold text-2xl whitespace-nowrap">{title}</h1>
        {isEditing ? (
          <SaveChangesModal
            hasErrors={hasErrors}
            isSubmitting={isSubmitting}
            onSave={onSave}
            onCancel={onCancel}
            title="Save Changes"
            description="Please review your updates to ensure all information is correct. This will permanently update your account with new details. Would you like to proceed?"
          />
        ) : (
          <EditButton onButtonClick={onEdit} />
        )}
      </div>
      <div className="flex flex-col">{children}</div>
    </div>
  );
};
