import React from 'react';

type StatusConfig = {
  background: string;
  text: string;
  border: string;
};

type StatusStyles = {
  [key: string]: StatusConfig;
};

type StatusType = 'success' | 'pending' | 'error' | 'neutral';

type StatusBadgeProps = {
  status: string;
  type?: 'status' | 'compliance_status' | 'payment_status';
  className?: string;
};

export const StatusBadge = ({
  status,
  type = 'status',
  className = '',
}: StatusBadgeProps) => {
  const statusStyles: StatusStyles = {
    success: {
      background: 'bg-smoothGreen',
      text: 'text-brightGreen',
      border: 'border-surfaceGreen',
    },
    pending: {
      background: 'bg-surfaceOrange',
      text: 'text-orange',
      border: 'border-darkOrange',
    },
    error: {
      background: 'bg-red-100',
      text: 'text-brightRed',
      border: 'border-lightRed',
    },
    neutral: {
      background: 'bg-gray-100',
      text: 'text-gray-700',
      border: 'border-gray-700',
    },
  };

  const getDisplayText = (rawStatus: string): string => {
    if (!rawStatus) return 'Pending';
    return rawStatus.charAt(0).toUpperCase() + rawStatus.slice(1).toLowerCase();
  };

  const getStatusType = (rawStatus: string): StatusType => {
    if (!rawStatus) return 'neutral';

    const normalizedStatus = rawStatus.toLowerCase().trim();

    if (['active', 'success', 'compliant'].includes(normalizedStatus)) {
      return 'success';
    }

    if (['created', 'pending'].includes(normalizedStatus)) {
      return 'pending';
    }

    if (
      [
        'deactivated',
        'non-compliant',
        'failed',
        'suspended',
        'inactive',
      ].includes(normalizedStatus)
    ) {
      return 'error';
    }

    if (['await submission', 'flagged'].includes(normalizedStatus)) {
      return 'neutral';
    }

    return 'pending';
  };

  const defaultWidth = type === 'compliance_status' ? 'w-44' : 'w-32';
  const defaultHeight = 'h-11';

  const statusType = getStatusType(status);
  const styles = statusStyles[statusType];
  const displayText = getDisplayText(status);

  return (
    <div className="flex items-center gap-2">
      <span
        className={`
          ${defaultWidth}
          ${defaultHeight}
          flex justify-center items-center
          font-semibold rounded-lg
          border
          ${styles.background}
          ${styles.text}
          ${styles.border}
          ${className}
        `.trim()}>
        {displayText}
      </span>
    </div>
  );
};

export default StatusBadge;
