import React, {ReactNode} from 'react';
import {Outlet} from 'react-router-dom';

import UnauthorisedAccess from './UnauthorisedAccess';

interface ProtectedRouteProps {
  hasAccess: boolean;
  children?: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  hasAccess,
  children,
}) => {
  if (!hasAccess) {
    return <UnauthorisedAccess />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
