import React from 'react';
import {useNavigate} from 'react-router-dom';

import ArrowLeft from '../../assets/icons/arrow-left.svg';
import {ReactComponent as Unauthorised} from '../../assets/icons/unauthorised.svg';
import Button from '../../components/common/Button';

const UnauthorisedAccess = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="text-center flex flex-col items-center">
        <Unauthorised />
        <p className="my-5 text-[34px] text-fontGrey font-[700]">
          Access Denied
        </p>
        <p className="w-[554px] text-greyscale font-medium text-base">
          You do not have the necessary permissions to access this section.
          Please contact your administrator for any further assistance.
        </p>

        <Button
          className="  mt-3"
          labelStyle="no-underline text-greyscale hover:text-mediumPurple text-center font-medium text-base"
          onClick={() => navigate('/dashboard')}
          text
          label="Return to home"
          leftIcon={ArrowLeft}
        />
      </div>
    </div>
  );
};

export default UnauthorisedAccess;
