type Props = {
  leftIcon?: string | JSX.Element;
  label: string | JSX.Element;
  rightIcon?: string | JSX.Element;
  onClick?: () => void;
  text?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  labelStyle?: string;
};

const Button = ({
  onClick,
  label,
  leftIcon,
  rightIcon,
  text = false,
  disabled = false,
  fullWidth = false,
  className,
  labelStyle,
}: Props) => {
  return (
    <div
      className={`select-none ${text ? 'bg-transparent' : ''} flex h-[38px] ${
        fullWidth ? 'w-full' : 'w-fit'
      } cursor-pointer flex-row items-center justify-between rounded-md p-3 text-white ${
        className || ''
      } ${disabled && !text ? 'cursor-not-allowed bg-darkPurple' : 'bg-greyish'}`}
      onClick={onClick}>
      <div className="mr-2">
        {typeof leftIcon === 'string' ? <img src={leftIcon} /> : leftIcon}
      </div>
      <p
        className={`text-center ${text ? 'text-black underline' : ''} ${
          labelStyle || ''
        } ${disabled ? 'cursor-not-allowed text-foggy' : ''} ${
          fullWidth ? 'flex w-full items-center justify-center' : ''
        }`}>
        {label}
      </p>
      <div className="ml-2">
        {typeof rightIcon === 'string' ? <img src={rightIcon} /> : rightIcon}
      </div>
    </div>
  );
};

export default Button;
