import {
  toast,
  Modal,
  ModalContent,
  ModalTitle,
  ModalDescription,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@cashiaApp/web-components';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as Tick} from '../../assets/icons/tick-circle.svg';
import CustomPhoneInput from '../../components/common/CustomPhoneInput';
import FormInput from '../../components/common/FormInput';
import Navbar from '../../components/common/Navbar';
import Spinner from '../../components/common/Spinner';
import RenderErrorMessage from '../../components/common/errorMessage';
import {
  GetUsersDocument,
  GetUsersQueryResult,
  UserRole,
  useCreateAdminUserMutation,
} from '../../generated';
import {
  hasDigits,
  isValidEmail,
  validateKenyanPhoneNumber,
} from '../../utils/helper/validation';
import {cn} from '../../utils/reusablefunctions';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: {
    countryCode: string;
    number: string;
  };
  role: string;
}

interface FormErrors {
  [key: string]: string;
}

type ValidateFunction = (values: FormValues) => FormErrors;

const useFormValidation = (
  initialState: FormValues,
  validate: ValidateFunction
) => {
  const [values, setValues] = useState<FormValues>(initialState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleRoleChange = (value: string) => {
    setValues({
      ...values,
      role: value,
    });
  };

  const reset = useCallback(() => {
    setValues(initialState);
    setErrors({});
    setIsSubmitting(false);
  }, [initialState]);

  return {
    handleRoleChange,
    handleChange,
    reset,
    values,
    setValues,
    errors,
    setErrors,
    isSubmitting,
    validate,
  };
};

const initialState: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: {
    countryCode: '+254',
    number: '',
  },
  role: '',
};

const roles = [
  {name: UserRole.Admin, value: 'Administrator'},
  {name: UserRole.Compliance, value: 'Compliance'},
  {name: UserRole.Finance, value: 'Finance'},
  {name: UserRole.Operations, value: 'Operations'},
  {name: UserRole.Support, value: 'Support'},
];

const validate: ValidateFunction = (values) => {
  const errors: FormErrors = {};

  if (values.firstName && hasDigits(values.firstName)) {
    errors.firstName = 'First name can only contain letters';
  }

  if (values.lastName && hasDigits(values.lastName)) {
    errors.lastName = 'Last name can only contain letters';
  }

  if (values.email && !isValidEmail(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (
    values.phoneNumber?.number?.trim() &&
    !validateKenyanPhoneNumber(values.phoneNumber.number.trim())
  ) {
    errors.phoneNumber = 'Phone number is invalid';
  }

  return errors;
};

const breadcrumbs = [
  {
    name: 'User',
    location: '/users',
  },
  {
    name: 'Add New User',
  },
];

const AddUser = () => {
  const [createAdminUser] = useCreateAdminUserMutation({
    update: (cache, {data: createData}) => {
      if (!createData) return;

      const {createAdminUser: newAdminUser} = createData;

      const input = {
        cursor: {
          first: 16,
          after: null,
        },
        roles: [
          UserRole.Finance,
          UserRole.Admin,
          UserRole.Compliance,
          UserRole.Operations,
          UserRole.Support,
        ],
      };

      try {
        const existingUsersData = cache.readQuery<GetUsersQueryResult>({
          query: GetUsersDocument,
          variables: {
            input,
          },
        });

        if (existingUsersData?.data?.users?.edges) {
          const updatedUsersList = [
            ...existingUsersData.data.users.edges.map(({node}) => ({
              ...node,
              __typename: 'User',
            })),
            {
              ...newAdminUser,
              __typename: 'User',
            },
          ];

          cache.writeQuery({
            query: GetUsersDocument,
            data: {
              users: {
                edges: updatedUsersList,
                pageInfo: existingUsersData.data.users.pageInfo,
              },
            },
            variables: {
              input,
            },
          });
        }
      } catch (error) {
        console.error(
          'Error updating the cache after creating admin user:',
          error
        );
      }
    },
  });

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {handleChange, handleRoleChange, values, setValues, errors, setErrors} =
    useFormValidation(initialState, validate);

  const selectedRole = useMemo(() => {
    return roles.filter((role) => role.value === values.role)[0]?.name;
  }, [values.role]);

  useEffect(() => {
    setErrors(validate(values));
  }, [values, setErrors]);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    const validationErrors = validate(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      try {
        await createAdminUser({
          variables: {
            input: {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: {
                countryCode: values.phoneNumber.countryCode,
                number: values.phoneNumber.number,
              },
              role: selectedRole,
            },
          },
        });
        setShowModal(true);

        setTimeout(() => {
          setShowModal(false);
          navigate('/users');
        }, 3000);
      } catch (error) {
        if (error instanceof Error) {
          if (error.message) {
            if (error.message.includes('email already exists')) {
              toast.error(
                'This email already exists. Please use a different email.'
              );
            } else if (error.message.includes('phone number already exists')) {
              toast.error(
                'This phone number already exists. Please use a different phone number.'
              );
            } else if (error.message.includes('invalid phone format')) {
              toast.error(
                'This phone number has the wrong format. Please use a different phone number.'
              );
            } else {
              toast.error('An unexpected error occurred');
            }
          }
        } else {
          toast.error('An unexpected error occurred');
        }
      }
    }
    setIsSubmitting(false);
  };
  const isButtonDisabled =
    !values.firstName.trim() ||
    !values.lastName.trim() ||
    !values.email.trim() ||
    !values.phoneNumber.number.trim() ||
    !values.role.trim() ||
    Object.keys(errors).length > 0 ||
    isSubmitting;

  return (
    <>
      <Navbar title="Add New User" omitSearch breadcrumbs={breadcrumbs} />
      <div className="flex h-screen overflow-y-hidden">
        <form className="w-full" onSubmit={handleFormSubmit}>
          <div className="border h-[60%] rounded-lg ml-8 w-[95%] mt-5 border-lightGrey">
            <div className="pl-8 pt-10 pb-3">
              <p className="font-semibold text-xl">User Information</p>
            </div>
            <div className="flex text-gray">
              <div className="w-[40%] ml-8 mr-3 ">
                <FormInput
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={(val: string) =>
                    handleChange({
                      target: {
                        name: 'firstName',
                        value: val.replaceAll(' ', ''),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                  maxLength={60}
                  error={<RenderErrorMessage error={errors.firstName} />}
                  required
                  className="bg-white w-full "
                />
              </div>

              <div className="w-[40%] ">
                <FormInput
                  label="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={(val: string) =>
                    handleChange({
                      target: {
                        name: 'lastName',
                        value: val.replaceAll(' ', ''),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                  maxLength={60}
                  error={<RenderErrorMessage error={errors.lastName} />}
                  required
                  className="bg-white w-full"
                />
              </div>
            </div>
            <div className="flex text-gray">
              <div className="w-[40%] ml-8 mr-3">
                <FormInput
                  label="Email"
                  className="bg-white w-full"
                  type="email"
                  name="Email"
                  value={values.email}
                  error={<RenderErrorMessage error={errors.email} />}
                  onChange={(val: string) =>
                    handleChange({
                      target: {
                        name: 'email',
                        value: val,
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                  maxLength={60}
                  required
                />
              </div>
              <div className="w-[40%]">
                <div className="flex-col">
                  <p className="text-gray font-medium text-sm mb-1">
                    Phone number *
                  </p>
                  <CustomPhoneInput
                    countryCode={values.phoneNumber?.countryCode || ''}
                    phoneNumber={values.phoneNumber?.number || ''}
                    onChange={(countryCode: string, number: string) =>
                      setValues((prevValues) => ({
                        ...prevValues,
                        phoneNumber: {countryCode, number},
                      }))
                    }
                  />
                  <RenderErrorMessage error={errors.phoneNumber} />
                </div>
              </div>
            </div>
            <div className="w-[40%] ml-8 mr-3 mb-10 text-gray">
              <p className="text-gray font-medium text-sm mb-1">Role *</p>
              <Select onValueChange={handleRoleChange}>
                <SelectTrigger
                  className={cn(
                    'w-full h-12 text-base font-medium border-lightGrey focus:outline-none focus:ring-0',
                    selectedRole
                      ? 'text-gray'
                      : 'focus:text-surfacePurple focus:bg-lightBlue focus:border-surfacePurple'
                  )}>
                  <SelectValue
                    className={cn(
                      'font-medium',
                      selectedRole ? 'text-gray' : 'focus:text-surfacePurple'
                    )}
                    placeholder="Select a role"
                  />
                </SelectTrigger>
                <SelectContent className="items-start text-sm border-lightGrey">
                  <SelectGroup className="items-start ml-[-22px]">
                    {roles.map((role, index) => (
                      <React.Fragment key={index}>
                        <SelectItem
                          value={role.value}
                          className="text-left font-medium text-gray  h-[40px]">
                          {role.value}
                        </SelectItem>
                        {index < roles.length - 1 && (
                          <hr className="border-lightGrey" />
                        )}
                      </React.Fragment>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <button
              disabled={isButtonDisabled}
              type="submit"
              className={cn(
                'border rounded-lg p-2 ml-8 font-normal text-base',
                {
                  'bg-lightGrey border-lightGrey text-foggy': isButtonDisabled,
                  'bg-surfacePurple text-white': !isButtonDisabled,
                }
              )}>
              {isSubmitting ? <Spinner className="fill-white" /> : 'Save'}
            </button>
          </div>
        </form>

        <Modal open={showModal}>
          <ModalContent className="w-[33%] pb-10 p-10 border-lightGrey">
            <ModalTitle>
              <div className="flex flex-col justify-center items-center mb-4">
                <Tick className="mb-5 h-10 w-10" />
                <p className="font-semibold text-2xl">User Created</p>
                <p className="font-semibold text-2xl">Successfully</p>
              </div>
            </ModalTitle>
            <ModalDescription>
              <hr className="mb-4 border-cashia-grey" />
              <div className="justify-between flex mb-2">
                <p className="text-base">First Name:</p>
                <p className="font-semibold text-base">{values.firstName}</p>
              </div>
              <div className="justify-between flex mb-2">
                <p className="text-base">Second Name:</p>
                <p className="font-semibold text-base">{values.lastName}</p>
              </div>
              <div className="justify-between flex mb-2">
                <p className="text-base">Email:</p>
                <p className="font-semibold text-base">{values.email}</p>
              </div>
              <div className="justify-between flex mb-2">
                <p className="text-base">Phone Number:</p>
                <p className="font-semibold text-base">
                  {values.phoneNumber.countryCode}
                  {values.phoneNumber.number}
                </p>
              </div>
              <div className="justify-between flex mb-2">
                <p className="text-base">Role:</p>
                <p className="font-semibold text-base">{values.role}</p>
              </div>
            </ModalDescription>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default AddUser;
