import React from 'react';
import {useNavigate} from 'react-router-dom';

import forwardArrow from '../../assets/icons/forwardArrow.svg';
import {cn} from '../../utils/reusablefunctions';

export type BreadcrumbType = {
  name: string | '';
  location?: string;
};

type Props = {
  breadcrumbs: BreadcrumbType[];
};

const Breadcrumb = ({breadcrumbs}: Props) => {
  const navigate = useNavigate();

  return (
    <ol className="flex items-center gap-3" aria-label="Breadcrumb">
      {breadcrumbs.map((item, i) => (
        <li key={i} className="flex cursor-pointer items-center gap-3">
          {i !== 0 && <img src={forwardArrow} />}
          <p
            onClick={() => item.location && navigate(item.location)}
            className={cn(
              `text-base	font-medium	leading-4 text-foggy hover:text-surfacePurple text-[#B2ABBF]`,
              {
                'font-semibold text-[#3A3441] decoration-grey':
                  i === breadcrumbs.length - 1,
              }
            )}>
            {item.name}
          </p>
        </li>
      ))}
    </ol>
  );
};

export default Breadcrumb;
