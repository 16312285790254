import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import CustomSpinner from '../../components/common/CustomSpinner';
import EmptyState from '../../components/common/EmptyState';
import {HeaderStats} from '../../components/common/Header';
import Navbar from '../../components/common/Navbar';
import Paginator from '../../components/common/Paginator';
import CommonTable from '../../components/common/table';
import {useGetUsersQuery, UserRole} from '../../generated';
import formatDate from '../../utils/reusablefunctions/formatDate';
import {TableColumnType, TableDataType} from '../../utils/types/table.types';

const columns: TableColumnType[] = [
  'full_name',
  'email',
  'role',
  'date_created',
  'status',
];

const validRoles = [
  UserRole.Admin,
  UserRole.Finance,
  UserRole.Compliance,
  UserRole.Support,
  UserRole.Operations,
];
const PAGE_SIZE = 16;

const formatRole = (role: string): string =>
  role.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

const Users = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(1);
  const [currentCursor, setCurrentCursor] = useState<string | null>(null);
  const [cursorStack, setCursorStack] = useState<string[]>([]);

  const {data, loading} = useGetUsersQuery({
    variables: {
      input: {
        cursor: {
          first: PAGE_SIZE,
          after: currentCursor,
        },
        roles: validRoles,
      },
    },
    fetchPolicy: 'network-only',
  });

  const transformedData = React.useMemo(() => {
    if (!data?.users.edges) return [];
    return data.users.edges.map(({node}) => ({
      id: node.id || undefined,
      role:
        node.roles
          .filter((role) => validRoles.includes(role))
          .map(formatRole)
          .join(', ') || '',
      full_name: [`${node.firstName || ''} ${node.lastName || ''}`.trim(), ''],
      email: node.email || undefined,
      date_created: node.createdAt
        ? formatDate(new Date(node.createdAt as string), {
            withTime: true,
            withNumericDate: true,
          })
        : '---',
      status: [node.status, '', ''],
    })) as TableDataType[];
  }, [data]);

  const loadMore = useCallback(
    async (next: boolean) => {
      try {
        if (next && data?.users.pageInfo.hasNextPage) {
          const nextCursor = data.users.pageInfo.endCursor;
          if (nextCursor) {
            if (currentCursor) {
              setCursorStack((prev) => [...prev, currentCursor]);
            }
            setCurrentCursor(nextCursor);
            setCount((prev) => prev + 1);
          }
        } else if (!next && count > 1) {
          if (cursorStack.length > 0) {
            const previousCursor = cursorStack[cursorStack.length - 1];
            if (previousCursor) {
              setCursorStack((prev) => prev.slice(0, -1));
              setCurrentCursor(previousCursor);
              setCount((prev) => prev - 1);
            }
          } else {
            setCurrentCursor(null);
            setCount(1);
          }
        }
      } catch (error) {
        console.error('Error loading more users:', error);
      }
    },
    [data, currentCursor, cursorStack, count]
  );

  const handleViewDetails = (id: string) => {
    navigate(`/users/user-details/${id}`);
  };

  const handleAddAccount = () => {
    navigate('/users/add-user');
  };

  return (
    <>
      <Navbar omitSearch title="Users" />
      <div className="px-6 py-4 h-full overflow-y-auto">
        {loading ? (
          <CustomSpinner />
        ) : data?.users.edges.length ? (
          <>
            <HeaderStats
              action={{
                label: 'Add New User',
                onClick: handleAddAccount,
              }}
            />

            <div className="flex flex-col h-full">
              <h2 className="text-2xl px-2 font-semibold">Users List</h2>

              <div className="bg-white rounded-lg">
                <CommonTable
                  columns={columns}
                  data={transformedData}
                  prettify
                  viewRecord={handleViewDetails}
                />
              </div>

              {data.users.pageInfo.entries > PAGE_SIZE && (
                <div className="mt-auto h-[60px] p-6">
                  <Paginator
                    count={count}
                    pageInfo={data.users.pageInfo}
                    loadMore={loadMore}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <EmptyState
            title="It's lonely out here"
            description="There are no users added yet. Click the button below to add a new user and start managing access."
            action={{
              label: 'Add New User',
              onClick: handleAddAccount,
            }}
          />
        )}
      </div>
    </>
  );
};

export default Users;
