import {Resolvers} from '@apollo/client';
import {nanoid} from 'nanoid/non-secure';

import {
  Address,
  Business,
  BusinessCategory,
  ComplianceStatus,
  CurrencyCode,
  RootMutationTypeResolvers,
  RootQueryTypeResolvers,
  User,
  UserRole,
  UserStatus,
  VerificationIdType,
} from '../../../generated';

export const mockUser: User = {
  __typename: 'User',
  id: '123',
  firstName: 'Melissa',
  lastName: 'Mukami',
  email: 'melissamukami@gmail.com',
  phone: {
    __typename: 'PhoneNumber',
    countryCode: '+254',
    number: '123456789',
  },
  createdAt: new Date().toISOString(),
  logo: {
    __typename: 'Image',
    id: nanoid(),
    small:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
    medium:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
    large:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
  },
  emailVerifiedAt: new Date().toISOString(),
  roles: [UserRole.Merchant],
  status: UserStatus.Active,
  businesses: [],
};

const mockAddress: Address = {
  __typename: 'Address',
  id: 'address_123',
  buildingName: '123 Main St',
  location: {
    __typename: 'Location',
    coordinates: {
      __typename: 'GeoCoordinates',
      lat: 1.2345,
      long: 2.3456,
    },
    formattedAddress: '123 Main St, City, State, Zip Code',
  },
};

const mockBusinessCategory: BusinessCategory = {
  __typename: 'BusinessCategory',
  code: '123',
  id: 'BusinessCategory_123',
  name: 'Travel Agencies & Tour Operators',
};

const queryResolvers: RootQueryTypeResolvers = {
  user: () => {
    return mockUser;
  },
  business: async (_, {id}): Promise<Business> => {
    return {
      __typename: 'Business',
      id: id || 'business_123',
      complianceStatus: ComplianceStatus.AwaitingSubmission,
      createdAt: new Date().toISOString(),
      description: 'This is just a mocked business profile',
      members: [mockUser],
      name: 'Mock Business',
      address: mockAddress,
      balance: [
        {
          __typename: 'BusinessBalance',
          currency: CurrencyCode.Kes,
          hold: 0,
          available: 89200.48,
          total: 120000.55,
          message: undefined,
        },
        {
          __typename: 'BusinessBalance',
          currency: CurrencyCode.Usd,
          hold: 0,
          available: 2400.0,
          total: 2600.0,
          message: undefined,
        },
      ],
      bankAccount: {
        __typename: 'BankAccount',
        accountHolder: 'Mock Bank Account',
        accountNumber: '1234567890',
        bank: {
          __typename: 'Bank',
          id: 'Bank_123',
          name: 'Mock Bank',
        },
        id: 'bankAccount_123',
      },
      category: mockBusinessCategory,
      owner: {
        __typename: 'BusinessOwner',
        address: mockAddress,
        firstName: 'Melissa',
        id: 'BusinessOwner_123',
        idPhotos: [],
        idType: VerificationIdType.NationalId,
        lastName: 'Mukami',
      },
      phoneNumber: {
        __typename: 'PhoneNumber',
        countryCode: '254',
        number: '722000009',
      },
      socialMedia: {
        __typename: 'BusinessSocialMedia',
        facebook: undefined,
        instagram: undefined,
        snapchat: undefined,
        tiktok: undefined,
        website: undefined,
      },
    };
  },
};

const mutationResolvers: RootMutationTypeResolvers = {};

const resolvers: Resolvers = {
  Query: {...queryResolvers},
  Mutation: {...mutationResolvers},
};

export default resolvers;
