import React, {
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
  useMemo,
} from 'react';
import {toast} from 'react-hot-toast';

import {isUser} from './apollo/helpers';
import {useAuth} from './auth';
import {useMeQuery, UserFieldsFragment, UserRole} from '../generated';

interface Context {
  user?: UserFieldsFragment;
  admin?: UserFieldsFragment;
  roleAccess: Permissions;
}

type Permissions = {
  canViewAccounts: boolean;
  canCreateAccount: boolean;
  canEditAccount: boolean;
  canViewBusinessProfiles: boolean;
  canCreateBusinessProfile: boolean;
  canEditBusinessProfile: boolean;
  canManageUsers: boolean;
  canViewPayments: boolean;
  canViewPayouts: boolean;
  canViewRefundsSummary: boolean;
  canViewRefundDetails: boolean;
  canViewLogs: boolean;
  canUpdateComplianceStatus: boolean;
};

const defaultPermissions: Permissions = {
  canViewAccounts: false,
  canCreateAccount: false,
  canEditAccount: false,
  canViewBusinessProfiles: false,
  canCreateBusinessProfile: false,
  canEditBusinessProfile: false,
  canManageUsers: false,
  canViewPayments: false,
  canViewPayouts: false,
  canViewRefundsSummary: false,
  canViewRefundDetails: false,
  canViewLogs: false,
  canUpdateComplianceStatus: false,
};

type RolePermissions = {
  [key in UserRole]: Permissions;
};

const rolePermissions: RolePermissions = {
  [UserRole.Admin]: {
    ...defaultPermissions,
    canViewAccounts: true,
    canCreateAccount: true,
    canEditAccount: true,
    canViewBusinessProfiles: true,
    canCreateBusinessProfile: true,
    canEditBusinessProfile: true,
    canManageUsers: true,
    canViewPayments: true,
    canViewPayouts: true,
    canViewRefundsSummary: true,
    canViewRefundDetails: true,
    canViewLogs: true,
    canUpdateComplianceStatus: true,
  },
  [UserRole.Operations]: {
    ...defaultPermissions,
    canViewAccounts: true,
    canCreateAccount: true,
    canEditAccount: true,
    canViewBusinessProfiles: true,
    canCreateBusinessProfile: true,
    canEditBusinessProfile: true,
    canViewPayments: true,
    canViewPayouts: true,
    canViewRefundDetails: true,
    canViewRefundsSummary: true,
    canViewLogs: true,
  },
  [UserRole.Support]: {
    ...defaultPermissions,
    canViewAccounts: true,
    canEditAccount: true,
    canViewBusinessProfiles: true,
    canEditBusinessProfile: true,
    canViewPayments: true,
    canViewPayouts: true,
    canViewRefundDetails: true,
    canViewRefundsSummary: true,
  },
  [UserRole.Compliance]: {
    ...defaultPermissions,
    canViewAccounts: true,
    canViewBusinessProfiles: true,
    canUpdateComplianceStatus: true,
  },
  [UserRole.Finance]: {
    ...defaultPermissions,
    canViewAccounts: true,
    canViewBusinessProfiles: true,
    canViewPayments: true,
    canViewPayouts: true,
    canViewRefundDetails: true,
  },
  [UserRole.Merchant]: defaultPermissions,
};

const mergePermissions = (roles: UserRole[]): Permissions => {
  return roles.reduce<Permissions>(
    (acc, role) => {
      const rolePerms = rolePermissions[role] || defaultPermissions;
      return {
        canViewAccounts: acc.canViewAccounts || rolePerms.canViewAccounts,
        canCreateAccount: acc.canCreateAccount || rolePerms.canCreateAccount,
        canEditAccount: acc.canEditAccount || rolePerms.canEditAccount,
        canViewBusinessProfiles:
          acc.canViewBusinessProfiles || rolePerms.canViewBusinessProfiles,
        canCreateBusinessProfile:
          acc.canCreateBusinessProfile || rolePerms.canCreateBusinessProfile,
        canEditBusinessProfile:
          acc.canEditBusinessProfile || rolePerms.canEditBusinessProfile,
        canManageUsers: acc.canManageUsers || rolePerms.canManageUsers,
        canViewPayments: acc.canViewPayments || rolePerms.canViewPayments,
        canViewPayouts: acc.canViewPayouts || rolePerms.canViewPayouts,
        canViewRefundsSummary:
          acc.canViewRefundsSummary || rolePerms.canViewRefundsSummary,
        canViewRefundDetails:
          acc.canViewRefundDetails || rolePerms.canViewRefundDetails,
        canViewLogs: acc.canViewLogs || rolePerms.canViewLogs,
        canUpdateComplianceStatus:
          acc.canUpdateComplianceStatus || rolePerms.canUpdateComplianceStatus,
      };
    },
    {...defaultPermissions}
  );
};

export const UserContext = React.createContext<Context>({
  roleAccess: defaultPermissions,
});

export const useUserAuth = (): Context => useContext(UserContext);

const UserProvider = ({children}: PropsWithChildren) => {
  const [user, setUser] = useState<UserFieldsFragment>();
  const {isAuthenticated} = useAuth();
  const {data, error} = useMeQuery({skip: !isAuthenticated});

  useEffect(() => {
    if (!!data?.me && isUser(data?.me) && !error) {
      setUser(data?.me || undefined);
    }
  }, [data, error]);

  useEffect(() => {
    if (!error || !isAuthenticated) return;
    toast.error(error.message, {
      duration: 4000,
      position: 'bottom-left',
    });
  }, [error, isAuthenticated]);

  const roleAccess = useMemo(() => {
    if (!user?.roles.length) return defaultPermissions;
    return mergePermissions(user.roles);
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        roleAccess,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
