import {Button, Checkbox} from '@cashiaApp/web-components';
import {useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import {ReactComponent as Plus} from '../../assets/icons/add-square-grey.svg';
import {ReactComponent as Close} from '../../assets/icons/close-square.svg';
import {cn} from '../../utils/reusablefunctions';

type FilterButtonProps = {
  options: string[];
  onApply: (filters: string[]) => void;
  className?: string;
  label: string;
};

export const FilterButton = ({
  options,
  onApply,
  label,
  className = '',
}: FilterButtonProps) => {
  const [open, setOpen] = useState(false);
  const [filters, setSelectedFilters] = useState<string[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
  const ref = useOnclickOutside(() => {
    setOpen(false);
  });

  const handleSelect = (option: string) => {
    setSelectedFilters((prev) => {
      if (filters.includes(option)) {
        return prev.filter((x) => x !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  return (
    <div className={`gap-4 ${className}`}>
      <Button
        ref={ref}
        variant="outline"
        className={cn(
          `bg-faintGrey text-black gap-2 flex items-center font-semibold border-dividerGrey hover:shadow-sm hover:border-1
      hover:border-mediumPurple hover:bg-faintGrey`,
          {
            'bg-surfacePurple text-white hover:bg-surfacePurple hover:text-white':
              !!appliedFilters.length,
          }
        )}
        onClick={() => setOpen(!open)}>
        {!appliedFilters.length ? <Plus /> : null}
        {appliedFilters.length ? appliedFilters.join(', ') : label}
        {appliedFilters.length ? (
          <Close
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
              setAppliedFilters([]);
              setSelectedFilters([]);
              onApply([]);
            }}
          />
        ) : null}
      </Button>
      {open && (
        <div
          ref={ref}
          className="bg-white shadow-md absolute p-4 mt-1 rounded-md">
          <p className="font-semibold mb-2">Filter by {label}</p>
          {options.map((option) => (
            <div className="flex items-center gap-3 mb-2 font-medium">
              <Checkbox
                className="border-[#BFBACB] checked:bg-surfacePurple checked:border-none"
                checked={filters.includes(option)}
                onClick={() => handleSelect(option)}
              />{' '}
              <span
                className="cursor-pointer"
                onClick={() => handleSelect(option)}>
                {option}
              </span>
            </div>
          ))}
          <Button
            className="w-full disabled:bg-surfaceGrey disabled:text-grey text-white bg-surfacePurple font-semibold"
            disabled={!filters.length}
            onClick={() => {
              onApply(filters);
              setOpen(false);
              setAppliedFilters(filters);
            }}>
            Apply
          </Button>
        </div>
      )}
    </div>
  );
};
