import {toast} from '@cashiaApp/web-components';
import {GraphQLFormattedError} from 'graphql';

import {GetUsersDocument, UserRole, useSignupMutation} from '../../generated';

export interface SignUpData {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  phone: {
    country_code: string;
    number: string;
  };
}

export interface UserData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: {
    countryCode: string;
    number: string;
  };
  createdAt: string;
  emailVerifiedAt: string | null;
}

export enum SignupErrorCode {
  INVALID_INPUT = 'INVALID_INPUT',
}

export type ErrorCode = SignupErrorCode;

export interface GraphQLError {
  message: string;
  extensions?: {
    code?: ErrorCode;
    field?: string;
  };
}

export const useSignUp = () => {
  const [signupMutation, {data, loading, error}] = useSignupMutation({
    refetchQueries: [
      {
        query: GetUsersDocument,
        variables: {
          input: {
            cursor: {
              first: 16,
              after: null,
            },
            roles: [UserRole.Merchant],
          },
        },
      },
      'GetUsers',
    ],
  });

  const signUp = async (userData: SignUpData) => {
    try {
      const response = await signupMutation({
        variables: {
          input: {
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.email,
            password: userData.password,
            phone: {
              number: userData.phone.number,
              countryCode: userData.phone.country_code,
            },
          },
        },
      });

      if (response.errors && response.errors.length > 0) {
        handleSignupErrors(response.errors);
      }

      if (!response.data?.signup) {
        throw new Error('Signup data is missing from the response');
      }

      return response.data.signup;
    } catch (err) {
      console.error('Error during signup:', err);
      throw err; // Rethrow the error to be handled in the form submit
    }
  };

  return {signUp, data, loading, error};
};

const handleSignupErrors = (errors: readonly GraphQLFormattedError[]): void => {
  errors.forEach((error) => {
    const {message, extensions} = error;
    const errorCode = extensions?.code as SignupErrorCode | undefined;
    const field = extensions?.field as string | undefined;

    switch (errorCode) {
      case SignupErrorCode.INVALID_INPUT:
        if (field) {
          let fieldName = field.charAt(0).toUpperCase() + field.slice(1);
          fieldName = fieldName.replace(/([A-Z])/g, ' $1').trim();

          toast.error(`Invalid ${fieldName}: ${message}`);
          throw new Error(`Invalid ${fieldName}: ${message}`);
        } else {
          toast.error(`Invalid input: ${message}`);
          throw new Error(`Invalid input: ${message}`);
        }

      default:
        if (
          message.includes('duplicate key value violates unique constraint')
        ) {
          if (message.includes('users_email_key')) {
            toast.error(
              'An account with this email already exists. Please use a different email.'
            );
            throw new Error('Email already in use');
          } else if (message.includes('idx_country_code_phone_number')) {
            toast.error(
              'This phone number is already registered. Please use a different number.'
            );
            throw new Error('Phone number already in use');
          }
        }
        toast.error(
          message || 'An error occurred during signup. Please try again.'
        );
        throw new Error(message || 'Signup error');
    }
  });
};

export const isSignupError = (code: ErrorCode): code is SignupErrorCode => {
  return Object.values(SignupErrorCode).includes(code);
};
