import {ReactComponent as MastercardIcon} from '../../assets/icons/MasterCard.svg';
import {ReactComponent as MpesaIcon} from '../../assets/icons/MpesaIcon.svg';
import {ReactComponent as VisaCardIcon} from '../../assets/icons/VisaCard.svg';
import {ReactComponent as DefaultCardIcon} from '../../assets/icons/default_card.svg';
import {CardIssuer} from '../../generated';

const getPaymentIcon = (method: string, cardIssuer?: CardIssuer | null) => {
  if (method === 'CARD' && cardIssuer) {
    switch (cardIssuer) {
      case CardIssuer.Visa:
        return <VisaCardIcon />;
      case CardIssuer.Mastercard:
        return <MastercardIcon />;
      default:
        return <DefaultCardIcon />;
    }
  }
  if (method === 'MPESA') {
    return <MpesaIcon />;
  }
  return <MpesaIcon />;
};

export default getPaymentIcon;
