import React from 'react';
import {useNavigate} from 'react-router-dom';

import ClipBoardIcon from '../../assets/icons/clipboard-text.svg';
import ExportIcon from '../../assets/icons/export.svg';
import UserSearch from '../../assets/icons/green_user-search.svg';
import DashboardImg from '../../assets/images/dashboard_hi_img.svg';
import Button from '../../components/common/Button';
import Navbar from '../../components/common/Navbar';
import {cn} from '../../utils/reusablefunctions';
import {useUserAuth} from '../../utils/user';

const featureDetails = [
  {
    icon: UserSearch,
    path: 'accounts',
    title: 'Accounts',
    linkTitle: 'View Accounts',
    linkIcon: ExportIcon,
    content:
      'Efficiently manage accounts by updating details and overseeing account activities',
  },
  {
    icon: ClipBoardIcon,
    path: 'business-profiles',
    title: 'Business Profiles',
    linkTitle: 'View Business Profiles',
    linkIcon: ExportIcon,
    content:
      'Efficiently manage business profiles by updating details and overseeing profile activities',
  },
];

const Dashboard = () => {
  const {user} = useUserAuth();
  const navigate = useNavigate();

  return (
    <div>
      <Navbar title="Dashboard" omitSearch />

      <div>
        <div className="w-[1120px] rounded-lg px-10 bg-surfacePurple h-[187px] flex flex-col text-faintGrey justify-center my-8 mx-10 relative overflow-hidden">
          <img
            src={DashboardImg}
            className="absolute inset-0 w-full h-full rounded-lg object-cover"
            alt="Background"
          />
          <div className="relative">
            <p className="font-semibold text-[28px] capitalize mb-4">
              Hey {user?.firstName || ''}!
            </p>
            <p className="w-[600px] font-medium text-base">
              Welcome back to the admin dashboard, your central hub for
              monitoring merchants and their related activities.
            </p>
          </div>
        </div>

        <p className="mx-10 font-semibold text-2xl mt-16 mb-3">Features</p>

        <div className="flex mb-8 mx-10 gap-4">
          {featureDetails.map((feature) => (
            <div className=" border border-surfaceGrey h-[360px] w-[552px]  rounded-md">
              <div className="pb-10 pt-16 px-4">
                <div className="flex flex-col items-center gap-4">
                  <div
                    className={cn(`rounded-full`, {
                      'bg-smoothGreen': feature?.icon === UserSearch,
                      'bg-lightBrown': feature?.icon !== UserSearch,
                    })}>
                    <img
                      className="p-4 w-[64px] h-[64px]"
                      src={feature?.icon}
                      alt={`${feature.title}-icon`}
                    />
                  </div>

                  <p className="text-grey font-semibold text-lg">
                    {feature.title}
                  </p>
                </div>

                <p className="w-[512px] text-textGrey font-normal text-sm text-center my-4">
                  {feature?.content}
                </p>

                <div className="flex justify-center group ">
                  <Button
                    className=" group-hover:text-mediumPurple"
                    labelStyle="no-underline text-surfacePurple group-hover:text-mediumPurple text-center font-medium text-base"
                    onClick={() => navigate(`/${feature?.path}`)}
                    text
                    label={feature?.linkTitle}
                    rightIcon={
                      <img
                        src={feature?.linkIcon}
                        className="transition-all group-hover:[filter:opacity(0.8)_brightness(0.9)_sepia(1)_hue-rotate(220deg)_saturate(5)]"
                        alt="link icon"
                      />
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
