import {ReactNode} from 'react';

type Variant = 'square' | 'circle';

type Props = {
  text?: string | ReactNode;
  subText?: string | ReactNode;
  variant?: Variant;
  restricted?: boolean;
};
const ImgWithText = ({
  text,
  subText,
  variant = 'square',
  restricted,
}: Props) => {
  return (
    <div
      className={`flex items-center  ${
        variant === 'square' ? 'gap-3' : 'gap-2'
      }`}>
      {subText ? (
        <div className="flex items-center gap-3">
          <p className="text-base font-medium capitalize">{text}</p>
          <p className="text-base  font-medium text-greyscale">{subText}</p>
        </div>
      ) : (
        <p
          className={`${
            restricted ? 'text-greyish' : 'text-darkGrey'
          }font-medium`}>
          {text}
        </p>
      )}
    </div>
  );
};

export default ImgWithText;
