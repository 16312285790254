import {Button, toast} from '@cashiaApp/web-components';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Logo from '../../assets/icons/cashia_logo.svg';
import {ReactComponent as EyeSlash} from '../../assets/icons/eye-slash.svg';
import {ReactComponent as Eye} from '../../assets/icons/eye.svg';
import LogInImg from '../../assets/images/admin_login_img.svg';
import FormInput from '../../components/common/FormInput';
import Spinner from '../../components/common/Spinner';
import {useAuth} from '../../utils/auth';
import RenderErrorMessage from '../../utils/reusablefunctions/errorMessage';

interface FormValues {
  email: string;
  password: string;
}

interface FormErrors {
  [key: string]: string;
}

const currentYear = new Date().getFullYear();

type ValidateFunction = (values: FormValues) => FormErrors;

const useFormValidation = (
  initialState: FormValues,
  validate: ValidateFunction
) => {
  const [values, setValues] = useState<FormValues>(initialState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const reset = useCallback(() => {
    setValues(initialState);
    setErrors({});
    setIsSubmitting(false);
  }, [initialState]);

  return {
    handleChange,
    reset,
    values,
    setValues,
    errors,
    setErrors,
    isSubmitting,
    validate,
  };
};

const initialState: FormValues = {
  email: '',
  password: '',
};

const validate: ValidateFunction = (values) => {
  const errors: FormErrors = {};

  if (!values.email.trim()) {
    errors.email = 'Email is required';
  }

  if (!values.password.trim()) {
    errors.password = 'Password is required';
  }

  return errors;
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const {handleChange, values, errors, setErrors} = useFormValidation(
    initialState,
    validate
  );
  const {makeLoginWithPost, loginError, isAuthenticated} = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    const validationErrors = validate(values);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      await makeLoginWithPost(values.email, values.password);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loginError) return;
    toast.error(loginError, {position: 'bottom-left'});
  }, [loginError]);

  useEffect(() => {
    if (!isAuthenticated) return;
    navigate('/dashboard');
  });

  return (
    <div className="h-screen w-screen flex justify-between bg-displayGrey">
      <div className="flex items-center flex-col w-full px-4 xl:px-0 lg:w-2/5 py-6 md:py-5 lg:py-4 2xl:py-20 overflow-y-auto">
        <div className="flex flex-col flex-grow max-w-md lg:ml-[60px] mt-10 w-full">
          <div className="flex justify-center lg:justify-normal">
            <img
              src={Logo}
              className="w-12 h-12 mb-6 mx-36 lg:mx-0"
              alt="Logo"
            />
          </div>
          <h2 className="text-5xl sm:font-medium lg:font-bold my-0 text-black">
            Log In
          </h2>
          <p className="text-greyish text-base sm:font-semibold xl:font-medium mb-4">
            Welcome to Cashia
          </p>
          <form className="flex flex-col gap-2">
            <FormInput
              label="Email"
              required
              value={values.email}
              className=" w-full"
              error={<RenderErrorMessage error={errors.email} />}
              maxLength={60}
              onChange={(val: string) =>
                handleChange({
                  target: {
                    name: 'email',
                    value: val,
                  },
                } as ChangeEvent<HTMLInputElement>)
              }
            />

            <div>
              <FormInput
                label="Password"
                type={showPassword ? 'text' : 'password'}
                rightElement={
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="p-2"
                    aria-label={
                      showPassword ? 'Hide password' : 'Show password'
                    }>
                    {showPassword ? (
                      <EyeSlash className="w-5 h-5 text-gray-700 " />
                    ) : (
                      <Eye className="w-5 h-5 text-gray-700" />
                    )}
                  </button>
                }
                required
                value={values.password}
                className=" w-full"
                error={<RenderErrorMessage error={errors.password} />}
                maxLength={60}
                onChange={(val: string) =>
                  handleChange({
                    target: {
                      name: 'password',
                      value: val,
                    },
                  } as ChangeEvent<HTMLInputElement>)
                }
              />
            </div>
          </form>

          <Button
            onClick={handleLogin}
            disabled={!values.email || !values.password}
            type="submit"
            className="bg-surfacePurple hover:bg-mediumPurple text-white w-full h-12 rounded-lg mt-8">
            {loading ? <Spinner className="fill-white" /> : 'Login'}
          </Button>

          <div className="flex justify-center items-center w-full mt-4">
            <Button
              variant="ghost"
              className="text-textPurple hover:text-mediumPurple text-sm font-medium">
              Forgot password?
            </Button>
          </div>
        </div>
        <div className="text-center px-4 max-w-md lg:ml-[60px] w-full xl:px-0 text-sm mb-2">
          <p className="text-gray">
            By submitting this form, I agree to the{' '}
            <span
              onClick={() => navigate('/terms')}
              className="text-textPurple hover:text-mediumPurple font-medium cursor-pointer whitespace-nowrap">
              Terms & Conditions
            </span>
          </p>
          <p className="mt-1 text-greyBlueish font-normal text-xs">
            <span className="text-greyBlueish">©</span> {currentYear} Cashia
            Limited All Rights Reserved.
          </p>
        </div>
      </div>

      <div className="w-[60%] pt-10">
        <div className="h-[95%] w-[95%] flex flex-col bg-faintGrey pt-14 items-center text-center rounded-[24px]">
          <p className="text-4xl font-semibold text-fontGrey w-[624px] mb-1">
            Manage Accounts, Monitor Activity, and Streamline Operations
          </p>
          <p className="text-sm text-textGrey font-medium px-10 w-[624px]">
            Seamlessly oversee accounts, track activities in real-time, and
            enhance operational efficiency with streamlined workflows and
            actionable insights.
          </p>
          <div className="mt-10 overflow-hidden">
            <img
              src={LogInImg}
              className="w-[1050px] h-[695px] rounded-br-[24px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
