import {ApolloError} from '@apollo/client';
import {Button, toast} from '@cashiaApp/web-components';
import React, {useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import Logo from '../../assets/icons/cashia_logo.svg';
import {ReactComponent as CopyrightIcon} from '../../assets/icons/copyright.svg';
import {ReactComponent as EyeSlashIcon} from '../../assets/icons/eye-slash.svg';
import {ReactComponent as EyeIcon} from '../../assets/icons/eye.svg';
import {ReactComponent as Tick} from '../../assets/icons/tick_green.svg';
import {ReactComponent as ValidationPointIcon} from '../../assets/icons/validation-point.svg';
import {ReactComponent as ValidationSuccessIcon} from '../../assets/icons/validation-success.svg';
import DashboardView from '../../assets/images/admin_login_img.svg';
import FormInput from '../../components/common/FormInput';
import Modal from '../../components/common/Modal';
import Spinner from '../../components/common/Spinner';
import {useSetPasswordMutation} from '../../generated';
import {useAuth} from '../../utils/auth';
import {
  hasDigits,
  hasLowerCaseChars,
  hasSpecialChars,
  hasUpperCaseChars,
} from '../../utils/helper/validation';
import RenderErrorMessage from '../../utils/reusablefunctions/errorMessage';

const requiredLength = 8;

const currentYear = new Date().getFullYear();

export default function SetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {isAuthenticated, logout} = useAuth();

  const [updatePassword, {loading}] = useSetPasswordMutation();

  const [validations, setValidations] = useState({
    hasLowerCaseChars: false,
    hasUpperCaseChars: false,
    hasDigits: false,
    hasSpecialChars: false,
    hasRequiredLength: false,
    passwordsMatch: false,
  });

  const handleChange = (
    field: 'password' | 'confirmPassword',
    text: string
  ) => {
    if (field === 'password') {
      setValidations((currentValidations) => ({
        ...currentValidations,
        hasDigits: hasDigits(text),
        hasSpecialChars: hasSpecialChars(text),
        hasLowerCaseChars: hasLowerCaseChars(text),
        hasUpperCaseChars: hasUpperCaseChars(text),
        hasRequiredLength: text.length >= requiredLength,
        passwordsMatch: confirmPassword.trim() === text.trim(),
      }));
      setPassword(text);
    } else {
      setConfirmPassword(text);
      setValidations((currentValidations) => ({
        ...currentValidations,
        passwordsMatch: password.trim() === text.trim(),
      }));
    }
  };

  const submit = async () => {
    if (!token) return;

    try {
      const res = await updatePassword({
        variables: {
          input: {
            token,
            password: password.trim(),
          },
        },
      });

      if (res.data?.setAdminPassword) {
        if (isAuthenticated) {
          await logout();
          toast.info(
            'Previous session has been logged out for security purposes'
          );
        }
        setShowModal(true);
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        toast.error('Unable to complete request. Please try again');
      }
    } catch (err: unknown) {
      if (err instanceof ApolloError) {
        toast.error(err.message);
      } else {
        toast.error('An unexpected error occurred');
        console.error('Unexpected error:', err);
      }
    }
  };

  return (
    <div className="flex h-screen">
      <div className="w-[40%] h-full flex flex-col justify-between items-center">
        <div className="pt-14">
          <img className="mb-4 w-[48px]" src={Logo} />
          <p className="text-5xl font-bold text-fontGrey">Set Your Password</p>
          <p className="text-lg font-medium text-[#B2ABBF] mb-4">
            Welcome to Cashia
          </p>
          <FormInput
            className="w-[440px] mb-2"
            label="Password"
            required
            type={showPassword ? 'text' : 'password'}
            onChange={(text) => handleChange('password', text)}
            rightElement={
              <div onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </div>
            }
          />
          <div className="flex gap-16 mt-[-14px] mb-6">
            <div className="">
              <div className="flex items-center gap-1">
                {validations.hasLowerCaseChars ? (
                  <ValidationSuccessIcon />
                ) : (
                  <ValidationPointIcon />
                )}
                <p className="text-sm text-textGrey">Lowercase Characters</p>
              </div>
              <div className="flex items-center gap-1">
                {validations.hasDigits ? (
                  <ValidationSuccessIcon />
                ) : (
                  <ValidationPointIcon />
                )}
                <p className="text-sm text-textGrey">Number</p>
              </div>
              <div className="flex items-center gap-1">
                {validations.hasSpecialChars ? (
                  <ValidationSuccessIcon />
                ) : (
                  <ValidationPointIcon />
                )}
                <p className="text-sm text-textGrey">Special Characters</p>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-1">
                {validations.hasUpperCaseChars ? (
                  <ValidationSuccessIcon />
                ) : (
                  <ValidationPointIcon />
                )}
                <p className="text-sm text-textGrey">Uppercase Characters</p>
              </div>
              <div className="flex items-center gap-1">
                {validations.hasRequiredLength ? (
                  <ValidationSuccessIcon />
                ) : (
                  <ValidationPointIcon />
                )}
                <p className="text-sm text-textGrey">8 Characters Minimum</p>
              </div>
            </div>
          </div>
          <FormInput
            className="w-[440px]"
            label="Re-enter Password"
            required
            type={showConfirmPassword ? 'text' : 'password'}
            onChange={(text) => handleChange('confirmPassword', text)}
            error={
              <RenderErrorMessage
                error={
                  confirmPassword && !validations.passwordsMatch
                    ? "Passwords don't match"
                    : undefined
                }
              />
            }
            rightElement={
              <div onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </div>
            }
          />
          <Button
            onClick={submit}
            disabled={!Object.values(validations).every(Boolean)}
            className="w-[440px] h-[44px] font-semibold mt-3 text-white text-sm bg-surfacePurple hover:bg-mediumPurple rounded-lg">
            {loading ? <Spinner className="fill-white" /> : 'Set Password'}
          </Button>
        </div>
        <div className="pb-20 text-center flex flex-col items-center">
          <p className="text-sm">
            By submitting this form, I agree to the{' '}
            <span className="text-[#9330F7] cursor-pointer font-semibold">
              Terms & Conditions
            </span>
          </p>
          <div className="flex items-center gap-1">
            <CopyrightIcon />
            <p className="text-xs text-textGrey">
              {currentYear} Cashia Limited All Rights Reserved
            </p>
          </div>
        </div>
      </div>
      <div className="w-[60%] pt-6">
        <div className="h-[95%] w-[95%] flex flex-col bg-faintGrey pt-14 items-center text-center rounded-[24px]">
          <p className="text-4xl font-semibold w-[624px] mb-1">
            Manage Accounts, Monitor Activity, and Streamline Operations
          </p>
          <p className="text-sm text-textGrey font-medium px-10 w-[624px]">
            Seamlessly oversee accounts, track activities in real-time, and
            enhance operational efficiency with streamlined workflows and
            actionable insights.
          </p>
          <div className="mt-14 overflow-hidden rounded-br-[24px]">
            <img src={DashboardView} className="w-[1050px] h-[695px]" />
          </div>
        </div>
      </div>
      <Modal
        onClose={() => setShowModal(false)}
        isVisible={showModal}
        className="h-[270px] w-[525px]">
        <div className="flex flex-col items-center justify-center h-full">
          <Tick className="mb-5 h-16 w-16" />
          <div className="flex flex-col items-center text-2xl font-semibold">
            <p>Password Set</p>
            <p>Successfully</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
