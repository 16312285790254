import {Toaster} from '@cashiaApp/web-components';
import {Route, Routes} from 'react-router-dom';

import MainLayout from '../layouts/MainLayout';
import AccountsPage from '../pages/Accounts';
import AccountDetails from '../pages/Accounts/AccountDetails';
import AddAccount from '../pages/Accounts/addAccount';
import BusinessProfiles from '../pages/BusinessProfiles';
import CreateProfile from '../pages/BusinessProfiles/CreateProfile';
import ProfileDetails from '../pages/BusinessProfiles/ProfileDetails';
import Dashboard from '../pages/Dashboard/Dashboard';
import NotFound from '../pages/Errors/NotFound';
import ProtectedRoute from '../pages/Errors/ProtectedRoute';
import Login from '../pages/Login';
import SetPassword from '../pages/Login/SetPassword';
import Payments from '../pages/Payments';
import PaymentDetails from '../pages/Payments/PaymentDetails';
import UserDetails from '../pages/Users/UserDetails';
import AddUser from '../pages/Users/addUser';
import Users from '../pages/Users/users';
import {useUserAuth} from '../utils/user';

const AppRoutes = () => {
  const {roleAccess} = useUserAuth();

  return (
    <>
      <Toaster richColors position="bottom-left" />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/user/set-password" element={<SetPassword />} />

        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<MainLayout />}>
          <Route index path="dashboard" element={<Dashboard />} />

          {/* Payments */}

          <Route
            element={<ProtectedRoute hasAccess={roleAccess.canViewPayments} />}>
            <>
              <Route index path="payments" element={<Payments />} />
              <Route
                path="payments/payment-details/:id"
                element={<PaymentDetails />}
              />
            </>
          </Route>

          {/* accounts */}

          <Route
            element={<ProtectedRoute hasAccess={roleAccess.canViewAccounts} />}>
            <>
              <Route index path="accounts" element={<AccountsPage />} />
              <Route
                path="accounts/account-details/:id"
                element={<AccountDetails />}
              />
            </>
          </Route>

          <Route
            element={
              <ProtectedRoute hasAccess={roleAccess.canCreateAccount} />
            }>
            <Route index path="accounts/add-account" element={<AddAccount />} />
            <Route
              index
              path="/accounts/account-details/:id/add-business-profile"
              element={<CreateProfile />}
            />
          </Route>

          {/* business profiles */}
          <Route
            element={
              <ProtectedRoute hasAccess={roleAccess.canViewBusinessProfiles} />
            }>
            <>
              <Route path="business-profiles" element={<BusinessProfiles />} />
              <Route
                path="business-profiles/details/:id"
                element={<ProfileDetails />}
              />
            </>
          </Route>
          {/* users */}

          <Route
            element={<ProtectedRoute hasAccess={roleAccess.canManageUsers} />}>
            <>
              <Route path="users" element={<Users />} />
              <Route index path="/users/add-user" element={<AddUser />} />
              <Route path="users/user-details/:id" element={<UserDetails />} />
            </>
          </Route>
        </Route>
      </Routes>
    </>
  );
};
export default AppRoutes;
